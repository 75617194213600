
import { Link } from "react-router-dom";

const styles = {
    accordion: {
        'a, a:visited, a:hover, a:active': {
            color: '#1E1941',
            border: "0px"
          },
        textAlign: 'left', 
        backgroundColor: "#1E1941", 
        border: "0px",
    }
}
const Footer = () => {


 
  return (
        <>

  {/* Start Footer Section */}
  <footer>
    <section className="footersec mt-3 pt-5 md-pt2">
      {/* Start Footer Container */}
      <div className="footer-container container">
        {/* Start Footer Row */}
        <div className="footer-row row justify-content-between">
          {/* Start Footer Col 1 */}
          <div className="col-md-3 md-mb2">
            <div className="footer-col footer-col-1">
              <a href="/" className="text-offwhite text-decoration-none">
                <picture>
                  <img
                    src="/images/chromia-logo.svg"
                    alt="Chromia Logo"
                    width={190}
                    height={49}
                  />
                </picture>
              </a>
            </div>
          </div>
          {/* End Footer Col 1 */}
          {/* Start Footer Col 2 */}
          <div className="col-md-8">
            <div className="footer-col footer-col-2">
              {/* Start Footer Col 2 Inner Row */}
              <div className="footer-col-2-row row justify-content-between pb-3">
                {/* Start Footer Col 2 Inner Col 1 */}
                
                {/* End Footer Col 2 Inner Col 1 */}
                {/* Start Footer Col 2 Inner Col 2 */}
                <div className="col-sm-5 col-lg-3 mb-md-4 mb-lg-0 md-mb">
                  <div className="footer-inner-col footer-inner-col-2">
                    <p className="mb-3">Chromia</p>
                    <ul>
                      <li>
                        <a
                          href="/" 
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          Blog
                        </a>
                      </li>
                      <li>
                        <a
                          href="/" 
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          Press &amp; Media kit
                        </a>
                      </li>
                      <li>
                        <a
                          href="/" 
                          rel="noopener"
                          className="coin text-offwhite text-decoration-none"
                        >
                          CoinMarketCap
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Footer Col 2 Inner Col 2 */}
                {/* Start Footer Col 2 Inner Col 3 */}
                <div className="col-sm-5 col-lg-3 md-mb">
                  <div className="footer-inner-col footer-inner-col-3">
                    <p className="mb-3">Developers</p>
                    <ul>
                     
                      <li>
                        <a
                          href="/" 
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          Web IDE
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          target="_blank"
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          Documentation
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Footer Col 2 Inner Col 3 */}
                {/* Start Footer Col 2 Inner Col 4 */}
                <div className="col-sm-5 col-lg-3">
                  <div className="footer-inner-col footer-inner-col-4">
                    <p className="mb-3">Whitepaper</p>
                    <ul>
                      <li>
                        <a
                          href="#"
                          target="_blank"
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          English version
                        </a>
                      </li>
                      <li>
                        <a
                          href="/" 
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          Chinese version
                        </a>
                      </li>
                      <li>
                        <a
                          href="/" 
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          Korean version
                        </a>
                      </li>
                      <li>
                        <a
                          href="/" 
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          Spanish version
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Footer Col 2 Inner Col 4 */}
              </div>
              {/* End Footer Col 2 Inner Row */}
            </div>
          </div>
          {/* ENd Footer Col 2 */}
        </div>
        {/* End Footer Row */}
        {/* Start Footer Bar */}
        <div className="footer-bar py-4">
          <div className="footer-bar-row d-flex justify-content-between">
            <a
              href="mailto:info@chromia.com"
              className="text-yellow text-decoration-none"
            >
              info@chromiaprotocol.io
            </a>
            <p>&copy; 2023 Chromia</p>
          </div>
        </div>
        {/* End Footer Bar */}
      </div>
      {/* End Footer Container */}
    </section>
  </footer>
  {/* End Footer Section */}
        </>
  );
};
export default Footer;
