 
import { Button } from "antd";
import { Link } from "react-router-dom";
import Footer from "../template/Footer";
import Nav from "../template/Nav";
import Buy from "./Buy";

const styles = {
    accordion: {
        'a, a:visited, a:hover, a:active': {
            color: '#1E1941',
            border: "0px"
          },
        textAlign: 'left', 
        backgroundColor: "#1E1941", 
        border: "0px",
    }
}
const ChrToken = () => {


    
  return (
     <>
     
     <Nav />

     <section className="banner-section chr-banner d-flex justify-content-center align-items-center">
  {/* Start Banner Container */}
  <div className="banner-container container">
    <div className="banner-content">
      {/* Start Banner Row */}
      <div className="banner-row row justify-content-between align-items-center">
        {/* Start Banner Col 1 */}
        <div className="col-md-6 logo-col md-mt2">
          <div className="banner-img banner-col text-center">
            <picture>
              <picture className="lozad">
                <source
                  type="image/webp"
                  srcSet="/static/img/chr_hero-ec122862-450.webp 450w, /static/img/chr_hero-ec122862-900.webp 900w, /static/img/chr_hero-ec122862-1350.webp 1350w"
                  sizes="450px"
                />
                <source
                  type="image/jpeg"
                  srcSet="/static/img/chr_hero-ec122862-450.jpeg 450w, /static/img/chr_hero-ec122862-900.jpeg 900w, /static/img/chr_hero-ec122862-1350.jpeg 1350w"
                  sizes="450px"
                />
                <img
                  src="/static/img/chr_hero-ec122862-450.jpeg"
                  alt="Chromia CHR Logo"
                  loading="lazy"
                  decoding="async"
                  width={450}
                  height={400}
                />
              </picture>
            </picture>
          </div>
        </div>
        {/* End Banner Col 1 */}
        {/* Start Banner Col 2 */}
        <div className="col-md-6">
          <div className="banner-txt banner-col text-center pt-2 px-3">
            <h1 className="text-offwhite font-heading sm-font-head">
              <span className="banner-span font-main ">the </span>CHR token{" "}
            </h1>
            <p className="text-offwhite sm-font-main md-mt">
              Chroma (CHR) is the native token designed to empower the Chromia
              platform and foster a mutually beneficial relationship between
              developers, users, and investors.
            </p>
          </div>
        </div>
        {/* End Banner Col 2 */}
      </div>
      {/* End Banner Row */}
    </div>
  </div>
  {/* End Banner Container */}
      </section>


      <section className="token-purpose-sec md-mt">
  {/* Start token purpose Container */}
  <div className="token-purpose-container container">
    {/* Start token purpose Head */}
    <div className="token-purpose-head text-center mb-4">
      <h3 className="sm-font-head font-heading">CHR token utility</h3>
    </div>
    {/* End token purpose Head */}
    {/* Start token purpose Row */}
    <div className="token-purpose-row row justify-content-between flex-wrap">
      {/* Start Col 1 */}
      <div className="col-md-6 mb-5 md-mb">
        <div className="token-purpose-col token-purpose-col-1">
          {/* Start token purpose Col Inner Row */}
          <div className="token-purpose-inner-row row justify-content-between">
            {/* Start Inner Col 1 */}
            <div className="col-sm-3 mb-2 mb-sm-0">
              <div className="token-purpose-inner-col token-purpose-inner-col-1  text-left text-sm-right">
                <picture>
                  <img
                    src="/images/token_page/token-purpose/platform-currency.svg"
                    alt="Platform Currency Icon"
                    width={60}
                    height={55}
                  />
                </picture>
              </div>
            </div>
            {/* End Inner Col 1 */}
            {/* Start Inner Col 2 */}
            <div className="col-sm-9">
              <div className="token-purpose-inner-col token-purpose-inner-col-2">
                <h6 className="font-main-bold mb-3">
                  Act as platform currency
                </h6>
                <p className="sm-font-main">
                  CHR is used as a “standard” currency within the Chromia
                  economy, as dapps can collect it as fees, or use as reserves
                  to peg their own tokens, etc.
                </p>
              </div>
            </div>
            {/* End Inner Col 2 */}
          </div>
          {/* Start token purpose Col Inner Row */}
        </div>
      </div>
      {/* End Col 1 */}
      {/* Start Col 2 */}
      <div className="col-md-6 mb-5 md-mb">
        <div className="token-purpose-col token-purpose-col-2">
          {/* Start token purpose Col Inner Row */}
          <div className="token-purpose-inner-row row justify-content-between">
            {/* Start Inner Col 1 */}
            <div className="col-sm-3 mb-2 mb-sm-0">
              <div className="token-purpose-inner-col token-purpose-inner-col-2 text-left text-sm-right">
                <picture>
                  <img
                    src="/images/token_page/token-purpose/hosting.svg"
                    alt="Hosting Fee icon"
                    width={60}
                    height={60}
                  />
                </picture>
              </div>
            </div>
            {/* End Inner Col 1 */}
            {/* Start Inner Col 2 */}
            <div className="col-sm-9">
              <div className="token-purpose-inner-col token-purpose-inner-col-2">
                <h6 className="font-main-bold mb-3">Payment of hosting fees</h6>
                <p className="sm-font-main">
                  CHR is used by dapps to pay hosting fees, thus compensating
                  the nodes.
                </p>
              </div>
            </div>
            {/* End Inner Col 2 */}
          </div>
          {/* Start token purpose Col Inner Row */}
        </div>
      </div>
      {/* End Col 2 */}
      {/* Start Col 3 */}
      <div className="col-md-6 mb-5 md-mb">
        <div className="token-purpose-col token-purpose-col-3">
          {/* Start token purpose Col Inner Row */}
          <div className="token-purpose-inner-row row justify-content-between">
            {/* Start Inner Col 1 */}
            <div className="col-sm-3 mb-2 mb-sm-0">
              <div className="token-purpose-inner-col token-purpose-inner-col-3 text-left text-sm-right">
                <picture>
                  <img
                    src="/images/token_page/token-purpose/ecosystem-staking.svg"
                    alt="Ecosystem staking Ion"
                    width={60}
                    height={60}
                  />
                </picture>
              </div>
            </div>
            {/* End Inner Col 1 */}
            {/* Start Inner Col 2 */}
            <div className="col-sm-9">
              <div className="token-purpose-inner-col token-purpose-inner-col-2">
                <h6 className="font-main-bold mb-3">Ecosystem staking</h6>
                <p className="sm-font-main">
                  CHR is used to make sure that providers have a stake in the
                  Chromia ecosystem thus offsetting incentives to collude.
                </p>
              </div>
            </div>
            {/* End Inner Col 2 */}
          </div>
          {/* Start token purpose Col Inner Row */}
        </div>
      </div>
      {/* End Col 3 */}
      {/* Start Col 4 */}
      <div className="col-md-6 mb-5 md-mb">
        <div className="token-purpose-col token-purpose-col-4">
          {/* Start token purpose Col Inner Row */}
          <div className="token-purpose-inner-row row justify-content-between">
            {/* Start Inner Col 1 */}
            <div className="col-sm-3 mb-2 mb-sm-0">
              <div className="token-purpose-inner-col token-purpose-inner-col-4 text-left text-sm-right">
                <picture>
                  <img
                    src="/images/token_page/token-purpose/system.svg"
                    alt="System Wide Icon"
                    width={60}
                    height={60}
                  />
                </picture>
              </div>
            </div>
            {/* End Inner Col 1 */}
            {/* Start Inner Col 2 */}
            <div className="col-sm-9">
              <div className="token-purpose-inner-col token-purpose-inner-col-2">
                <h6 className="font-main-bold mb-3">System-wide purposes</h6>
                <p className="sm-font-main">
                  Chromia has several special Chroma token accounts that are
                  used for system-wide purposes, such as ERC20 token pegging,
                  system node compensation pool, development pool, etc.
                </p>
              </div>
            </div>
            {/* End Inner Col 2 */}
          </div>
          {/* Start token purpose Col Inner Row */}
        </div>
      </div>
      {/* End Col 3 */}
    </div>
    {/* End token purpose Row */}
  </div>
  {/* End token purpose Container */}
</section>


<section
  className="key-points-sec bg-light-dark py-5 md-py"
  id="key-data-points"
>
  {/* Start Key token points Container */}
  <div className="key-points-container container">
    {/* Start Key token points Title */}
    <div className="key-points-title text-center pb-5 md-pb">
      <h3 className="sm-font-head font-heading">Key data points</h3>
    </div>
    {/* End Key token points title */}
    {/* Start Key token points Title */}
    <div className="key-points-row row justify-content-between">
      {/* Start Col 1 */}
      <div className="w-24-md key-points-main bg-cdark d-flex justify-content-center align-items-center mt-3 mt-md-0">
        <div
          className="key-points-col key-points-col-1 text-center p-3 d-flex flex-column align-self-end"
          title="Approximate price (internal data)"
        >
          <h6 className="font-main-bold text-purple text-uppercase">
            CHR PRICE
          </h6>
          <p className="sm-font-main mt-4">$ 0.171</p>
        </div>
      </div>
      {/* End Col 1 */}
      {/* Start Col 2 */}
      <div className="w-24-md key-points-main bg-cdark d-flex justify-content-center align-items-center mt-3 mt-md-0">
        <div className="key-points-col key-points-col-1 text-center p-3 d-flex flex-column align-self-end">
          <h6 className="font-main-bold text-purple text-uppercase">
            Current circulating supply
          </h6>
          <p className="sm-font-main mt-4">562,296,409 CHR</p>
        </div>
      </div>
      {/* End Col 2 */}
      {/* Start Col 3 */}
      <div className="w-24-md key-points-main bg-cdark d-flex justify-content-center align-items-center mt-3 mt-md-0">
        <div className="key-points-col key-points-col-1 text-center p-3 d-flex flex-column align-self-end">
          <h6 className="font-main-bold text-purple text-uppercase">
            Total <br /> circulating supply
          </h6>
          <p className="sm-font-main mt-4">978,000,000 CHR</p>
        </div>
      </div>
      {/* End Col 3 */}
      {/* Start Col 4 */}
      <div className="w-24-md key-points-main bg-cdark d-flex justify-content-center align-items-center mt-3 mt-md-0">
        <div className="key-points-col key-points-col-1 text-center p-3 d-flex flex-column align-self-end">
          <h6 className="font-main-bold text-purple">Staked CHR tokens</h6>
          <p className="sm-font-main mt-4">108,845,824 CHR</p>
        </div>
      </div>
      {/* End Col 4 */}
    </div>
    {/* End Key token points title */}
  </div>
  {/* ENd Key token points Container */}
</section>


<section className="relase-sched-sec py-5 md-py">
  {/* Start Release Schedule Container */}
  <div className="relase-sched-container container">
    {/* Start Release Schedule Content */}
    <div className="relase-sched-content">
      {/* Start Release Schedule Title Content */}
      <h3 className="font-heading sm-font-head text-center mb-5 md-mb">
        Release schedule
      </h3>
      {/* End Release Schedule Title Content */}
      {/* Start Release Schedule Graph Container  */}
      <div className="release-schedule-graph position-relative">
 
        <img src="/images/blob189.png" />
      </div>
      {/* End Release Schedule Graph Container */}
      {/* Start Release Schedule Graph Labels */}
      <div className="release-schedule-graph-labels row justify-content-between mx-0 mt-4">
        {/* Start Col 1 */}
        <div className="col-md-4">
          <div className="release-sched-col release-sched-col-1">
            <p className="sm-font-main eco-fund">Ecosystem Fund</p>
            <p className="sm-font-main pro-fund">Promotional Fund</p>
            <p className="sm-font-main pri-sale">Private Sale</p>
          </div>
        </div>
        {/* End Col 1 */}
        {/* Start Col 2 */}
        <div className="col-md-4">
          <div className="release-sched-col release-sched-col-2">
            <p className="sm-font-main founder">Founders</p>
            <p className="sm-font-main ieo">IEO</p>
            <p className="sm-font-main acc">Auto Conversion Contract</p>
          </div>
        </div>
        {/* End Col 2 */}
        {/* Start Col 3 */}
        <div className="col-md-4">
          <div className="release-sched-col release-sched-col-3">
            <p className="sm-font-main adv">Advisors</p>
            <p className="sm-font-main team">Team</p>
            <p className="sm-font-main sncp">
              System Node <br /> Compensation Pool
            </p>
          </div>
        </div>
        {/* End Col 3 */}
      </div>
      {/* End Release Schedule Graph Labels */}
      <p className="sm-font-main mt-4">
        The graph shows the number and breakdown of the CHR tokens that will be
        released into circulation on a monthly basis. The Advisors, Promotion
        Fund, Ecosystem Fund, and Conversion Contract tokens will continue to be
        distributed in 2025 and beyond.
      </p>
    </div>
    {/* End Release Schedule Content */}
  </div>
  {/* End Release Schedule Container */}
</section>


<section className="token-dist-sec py-5 md-py">
  {/* Start token distribution Container */}
  <div className="token-dist-container container">
    {/* Start token distribution Content */}
    <div className="token-dist-content">
      {/* Start token distribution Row */}
      <div className="token-dist-row row justify-content-between">
        {/* Start Col 1 */}
        <div className="col-md-4">
          <div className="token-dist-col token-dist-col-1">
          <img src="/images/blob5409.png"  id="pie_chart" width={300} height={300}/>
           
          </div>
        </div>
        {/* End Col 1 */}
        {/* Start Col 2 */}
        <div className="col-md-8 md-mt">
          <div className="token-dist-col token-dist-col-2">
            {/* Start token distribution Title */}
            <div className="token-dist-title">
              <h3 className="font-heading mb-4 sm-font-head">
                CHR token distribution
              </h3>
              {/* End token distribution Title */}
              <p className="sm-font-main">
                One billion CHR tokens was created upon launch of the Chromia
                system. That constitutes the token supply limit, which means
                that no tokens will be created in the future. The following
                pie-chart illustrates the initial CHR token distribution.
              </p>
            </div>
            {/* Start Pie Chart Custom Labels */}
            <section className="pie-chart-labels mt-4">
              <div className="pie-chart-label-row row justify-content-between no-gutters">
                {/* Start Inner Col 1 */}
                <div className="col-md-5">
                  <div className="pie-chart-inner-col pie-chart-inner-col-1">
                    <div className="inner-col-row d-flex justify-content-between">
                      <p className="sm-font-main eco-fund">Ecosystem Fund</p>{" "}
                      <span className="ml-2 font-main-bold">38.06%</span>
                    </div>
                    <div className="inner-col-row d-flex justify-content-between">
                      <p className="sm-font-main pro-fund">Promotional Fund</p>{" "}
                      <span className="ml-2 font-main-bold">25%</span>
                    </div>
                    <div className="inner-col-row d-flex justify-content-between">
                      <p className="sm-font-main pri-sale">Private Sale</p>{" "}
                      <span className="ml-2 font-main-bold">17.94%</span>
                    </div>
                    <div className="inner-col-row d-flex justify-content-between">
                      <p className="sm-font-main founder">Founders</p>{" "}
                      <span className="ml-2 font-main-bold">4.5%</span>
                    </div>
                    <div className="inner-col-row d-flex justify-content-between">
                      <p className="sm-font-main ieo">IEO</p>{" "}
                      <span className="ml-2 font-main-bold">4%</span>
                    </div>
                  </div>
                </div>
                {/* End Inner Col 1 */}
                {/* Start Inner Col 2 */}
                <div className="col-md-5">
                  <div className="pie-chart-inner-col pie-chart-inner-col-2">
                    <div className="inner-col-row d-flex justify-content-between">
                      <p className="sm-font-main acc">
                        Auto Conversion Contract
                      </p>{" "}
                      <span className="ml-2 font-main-bold">3%</span>
                    </div>
                    <div className="inner-col-row d-flex justify-content-between">
                      <p className="sm-font-main adv">Advisors</p>{" "}
                      <span className="ml-2 font-main-bold">3%</span>
                    </div>
                    <div className="inner-col-row d-flex justify-content-between">
                      <p className="sm-font-main team">Team</p>{" "}
                      <span className="ml-2 font-main-bold">2.5%</span>
                    </div>
                    <div className="inner-col-row d-flex justify-content-between">
                      <p className="sm-font-main sncp">
                        System Node <br /> Compensation Pool
                      </p>{" "}
                      <span className="ml-2 font-main-bold">2%</span>
                    </div>
                  </div>
                </div>
                {/* End Inner Col 2 */}
              </div>
            </section>
            {/* End Pie Chart Custom Labels */}
          </div>
        </div>
        {/* End Col 2 */}
      </div>
      {/* End token distribution Row */}
    </div>
    {/* End token distribution Content */}
  </div>
  {/* End token distribution Container */}
</section>


<section className="faq-sec py-5 md-py">
  {/* Start faq Container */}
  <div className="faq-container container">
    {/* Start faq Title */}
    <h3 className="font-heading text-center mb-4 sm-font-head">Buy CHR</h3>
    {/* Start faq Title */}
    {/* Start faq Content */}
    <div className="faq-content">
      {/* Start Where to buy */}

      <div className="where-buy-container">
         
      <Buy />

    
      </div>

 
    </div>
    {/* End faq Content */}
  </div>
  {/* End faq Container */}
</section>



{ /* faq will be here first */}

<section className="faq-sec py-5 md-py">
  {/* Start faq Container */}
  <div className="faq-container container">
    {/* Start faq Title */}
    <h3 className="font-heading text-center mb-4 sm-font-head">FAQ</h3>
    {/* Start faq Title */}
    {/* Start faq Content */}
    <div className="faq-content">
      {/* Start Where to buy */}

      <div className="where-buy-container">
  <h4 className="font-main-bold sm-font-main2 mb-3">Where to buy CHR?</h4>
  <p className="sm-font-main">
    You can buy right here with the form above or on several major cryptocurrency exchanges. Information about
    where to buy the CHR token can be found on
     
      CoinMarketCap
    
    and 
    
      Coingecko 
  </p>
</div>


      {/* Start CHR Stake Section */}
      <div className="chr-stake-container mt-5 md-mt">
        <h4 className="font-main-bold sm-font-main2 mb-3">
          How do I stake CHR?
        </h4>
        <p className="sm-font-main">
          If you own CHR tokens, you can stake them at 
          <a
            href="/chr-token"
            target="_blank"
            className="text-purple"
          >
            Chromia
          </a>{" "}
           
        </p>
      </div>
      {/* End CHR Stake Section */}
      {/* End Where to buy */}
      {/* Start CHR ERC Section */}
      <div className="chr-erc-container mt-5 md-mt">
        <h4 className="font-main-bold sm-font-main2 mb-3">
          Is CHR an ERC20 token?
        </h4>
        <p className="sm-font-main">
          Currently, CHR is an ERC20 token. However, when the Chromia mainnet is
          launched, there will be a transition from an ERC20 token to a native
          one.
        </p>
      </div>
      <div className="chr-erc-container mt-5 md-mt">
        <h4 className="font-main-bold sm-font-main2 mb-3">
          What is the source of your data?
        </h4>
        <ul>
          <li className="mb-3 sm-font-main">
            The CHR price is an average of yesterday’s closing price on some
            major exchanges.
          </li>
          <li className="mb-3 sm-font-main">
            Current circulating supply is taken from the smart contract as of
            yesterday.
          </li>
          <li className="mb-3 sm-font-main">
            Total circulating supply is as in whitepaper minus burned tokens.
          </li>
          <li className="mb-3 sm-font-main">
            The number for staked tokens is taken from the smart contract as of
            yesterday.
          </li>
        </ul>
      </div>
      <div className="chr-erc-container mt-5 md-mt">
        <h4 className="font-main-bold sm-font-main2 mb-3">
          Wasn't 1,000,000,000 CHR the total circulating supply?
        </h4>
        <p className="sm-font-main">
          As stated in the white paper 1,000,000,000 CHR is the maximum number
          of CHR tokens that can ever be minted. However, the circulating supply
          got reduced after the burn of 22M CHR in May 2020.
        </p>
      </div>
      {/* End CHR ERC Section */}
      {/* Start Chr Functions Section */}
      <div className="chr-function-container mt-5 md-mt">
        <h4 className="font-main-bold sm-font-main2 mb-3">
          What are the functions of the CHR token within the Chromia platform?
        </h4>
        <p className="sm-font-main mb-3">
          We believe that CHR is the “bread and butter” of the Chromia platform.
          It has the following roles in Chromia:
        </p>
        <ul>
          <li className="mb-3 sm-font-main">
            It is used by dapps to pay hosting fees, thus compensating the
            nodes. Similar to how tokens are used in Ethereum to pay transaction
            fees and compensate block producers, Chroma tokens are used in
            Chromia to compensate block-producing nodes.
          </li>
          <li className="mb-3 sm-font-main">
            It is used as a “standard” currency within the Chromia economy, as
            dapps can collect it as fees, or use it as reserves to peg their own
            tokens, etc.
          </li>
          <li className="mb-3 sm-font-main">
            It is used to make sure that providers have a stake in the Chromia
            ecosystem thus offsetting incentives to collude.
          </li>
          <li className="mb-3 sm-font-main">
            Chromia has several special Chroma token accounts that are used for
            system-wide purposes.
          </li>
        </ul>
        <p className="sm-font-main">
          To read more about the CHR token ecosystem in our{" "}
          <a href="/whitepaper-english.html" className="text-purple">
            whitepaper
          </a>
          .
        </p>
      </div>
      {/* End Chr Functions Section */}
      {/* Start CHR Address Section */}
      <div className="chr-address-container mt-5 md-mt">
        <h4 className="font-main-bold sm-font-main2 mb-3">
          What is the CHR token contract address?
        </h4>
        <p className="sm-font-main">
          You can review the CHR token address at Etherscan:
          
        </p>
      </div>
      {/* End CHR Address Section */}
      {/* Start CHR Disclaimer Section */}
      <div className="chr-disclaimer-container mt-5 md-mt">
        <p className="sm-font-main">
          <span className="font-main-bold">Disclaimer:</span> The CHR token is
          designed as a utility token that can be used within the Chromia
          platform. The information provided on this website is solely for
          information purposes only and does not constitute investment advice
          whatsoever.
        </p>
      </div>
      {/* End CHR Disclaimer Section */}
    </div>
    {/* End faq Content */}
  </div>
  {/* End faq Container */}
</section>














<section className="newsletter-sec bg-purple py-5 md-py2">
  {/* Start Newsletter Container */}
  <div className="newsletter-container container">
    {/* Start Newsletter Content */}
    <div className="newsletter-content py-3 mx-auto">
      <h2 className="font-heading text-cdark text-center mb-4 sm-font-head">
        Subscribe to our newsletter
      </h2>
      <form
        action="https://chromaway.us13.list-manage.com/subscribe/post?u=40717d7a493ac7c48b9533d09&id=78394188c0"
        method="post"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        rel="noopener"
        noValidate=""
      >
        <label className="text-cdark mb-4 text-center sm-font-main d-block">
          Join 28.000 others interested in our monthly updates.
        </label>
        <div className="newsletter-input-container d-flex">
          <input
            type="email"
            name="EMAIL"
            className="js-email form-control btn-border border bg-transparent"
            placeholder="Enter your email"
            required="required"
          />
          {/* <button class="btn btn-newsletter btn-border bg-dark-2"><a href="#" class="d-inline-block text-offwhite">Subscribe</a></button> */}
          {/*    real people should not fill this in and expect good things - do not remove this or risk form bot signups   */}
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_40717d7a493ac7c48b9533d09_78394188c0"
              tabIndex={-1}
              defaultValue=""
            />
          </div>
          <input
            type="submit"
            defaultValue="Subscribe"
            name="subscribe"
            className="js-mc-submit btn btn-newsletter btn-border border-0 bg-dark-2 d-inline-block text-offwhite"
          />
        </div>
        <div className="js-email-msg-area newsletr-msg font-main-bold px-3 mt-1" />
      </form>
    </div>
    {/* End Newsletter Content */}
  </div>
  {/* End Newsletter Container */}
</section>

 
 <Footer />
</>
 
  );
};
export default ChrToken;
