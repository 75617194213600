 import { useEffect, useState } from "react";
import Dapp from "./Dapp";
import DisplayPane from "../components/displayPane/DisplayPane";
import ConnectAccount from "../components/Account/ConnectAccount";
import ChainSelector from "../components/ChainSelector";
import { Divider } from "antd";
import Nav from "../template/Nav";
import { Link } from "react-router-dom";
//import mainJs from "../components/script/mainJs";




const styles = {
  content: {
    //backgroundColor: "#E1EBFF",
    display: "flex",
   // justifyContent: "center",
    alignItems: "flex-start",
    color: "black",
     
   // padding: "10px",
    overflow: "auto",
    border: "0px",
    borderRadius: "0px",
    
   
  },
};
const Buy = () => {

  const [rate, setRate] = useState('');
  const [bnbPrice, setBnbprice] = useState(0);
  const [btcPrice, setBtcprice] = useState(0);
  const [busdPrice, setBusdprice] = useState(0);
  const [usdtPrice, setUsdtprice] = useState(1);
  
  const [paymentmethod, setPaymentmethod] = useState('bnb');
  const [inputAmount, setInputamount] = useState(0);
  const [highAmount, setHighAmount] = useState(0);
  const [cryptoTopay, setCryptoTopay] = useState(0);
  const [usdtTohigh, setUsdttohigh] = useState(1.13560000);
  const [showButton, setShowbutton] = useState(true);
  const wallet = "0x22435B3c8fB160122D04E17C3865B4EeB896bF55";
  const [errorMessage, setErrorMessage] = useState('');
  const tokenName = 'Chromia';

  var url = "https://link.trustwallet.com/send/";
  

  async function getapiatabnb() {
      const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD");
      var data = await response.json();
      var price = data.USD - (0.152 * data.USD);
      var price1 = price.toFixed(8);
      console.log('bnb price' + price1);
      setBnbprice(price1);
      
    }

    async function getapiatabtc() {
      const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=BTC&tsyms=USD");
      var data = await response.json();
      var price = data.USD - (0.152 * data.USD);
      var price2 = price.toFixed(8);
       
        setBtcprice(price2);
    }

    async function getapiatabusd() {
        const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=BUSD&tsyms=USD");
        var data = await response.json();
        var price = data.USD - (0.15 * data.USD);
        var price3 = price.toFixed(8);
       
          setBusdprice(price3);
    }
  
    async function getapiatausdt() {
          
        setUsdtprice(1);
      }

      async function getapiatahigh() {
        const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=CHR&tsyms=USD");
        var data = await response.json();
        var price = data.USD - (0.15 * data.USD);
        var price3 = price.toFixed(8);
         
        setUsdttohigh(price3);
    }

  useEffect(() => {

      //mainJs();
      getapiatabnb();
      getapiatabtc();
      getapiatabusd();
      getapiatausdt();
      getapiatahigh();

  }, []);


  function paymentMethodChange(payMethod){

    if(payMethod == ""){
      setShowbutton(false);
      setErrorMessage('Please select payment method');
      return false;
      
    }

    if(inputAmount < 100){
      setShowbutton(false);
      setErrorMessage('Please select payment method');
    }else{

      setShowbutton(true);
      setErrorMessage('');
    }
    setPaymentmethod(payMethod);
    updateInputAmount(inputAmount);  
   
   
  }


 function updateInputAmount(amount){

  if(amount < 100){
    setShowbutton(false);
    setErrorMessage('Minimum exchange $100');
    return false;
  }
  setShowbutton(true);
   setInputamount(amount);
   let myRate = rate;

   if(paymentmethod == "bnb"){
        
    myRate = bnbPrice;

  }else if(paymentmethod == "btc"){
    myRate = btcPrice;

  }else if(paymentmethod == "busd"){
     
    myRate = busdPrice;

  }else if(paymentmethod == "usdt"){
    myRate = usdtPrice;
  }
    var cryptoAmount = (1/myRate) * amount;
    //set crypto amount
    setCryptoTopay(cryptoAmount.toFixed(4));
    //now set high token to receive
    var highrate = (1/usdtTohigh) * amount;
    setHighAmount(highrate.toFixed(4)); 
    

 } 

 function buyButton(){
  
    if(paymentmethod == "bnb"){
        var newurl =  url + "?coin=714&address=" + wallet + "&amount=" + cryptoTopay;
       window.location.href = newurl;
    }else if(paymentmethod == "btc"){
      var newurl =  url + "?coin=0&address=" + wallet + "&amount=" + cryptoTopay;
      window.location.href = newurl;
    }else if(paymentmethod == "busd"){
      var newurl =  url + "?coin=20000714&address=" + wallet + "&amount=" + cryptoTopay + "&token_id=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
       window.location.href = newurl;
        
    }else if(paymentmethod == "usdt"){

       var newurl =  url + "?coin=20000714&address=" + wallet + "&amount=" + cryptoTopay + "&&token_id=0x55d398326f99059fF775485246999027B3197955";
       window.location.href = newurl;
    }else{

       return false;
    }
 
 }
 
  return (
    <> 
    

     
       

        <div className="accordion" id="accordionExample">

          <div className="row">
          <div >
                <div className="card"  style={{backgroundColor: "#1E191E"}}>
                <div className="card-header" id="headingTwo">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      style={{color: 'white'}}
                    >
                      Select Web3
                    </button>
                   
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse show"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    
                      <ConnectAccount /><ChainSelector />
                    
                    
                    <Divider />
                    <DisplayPane />
                  </div>
                </div>
                </div>
            </div>
            <div >
              <div className="card" style={{backgroundColor: "#1E191E"}}>
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button
                      cl className="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      style={{color: 'white'}}
                    >
                      Direct Swap
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                  <div className="row text-center mb-2">
              
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    borderBottom: "3px solid rgb(2, 211, 226)",
                    paddingBottom: 15
                  }}
                >
                  Swap Chr

                
                </span>
              
            </div>
            <div className="form-group mt-4" >
              <div id="swapInfo" className="mb-3">
               <div className="row">
                <div className="col-6">
                   
                    <select required="" className="form-control" onChange={(e) => paymentMethodChange(e.target.value)}>
                     
                      <option value="bnb">BNB</option>
                      <option value="btc">BTC</option>
                      <option value="busd">BUSD</option>
                      <option value="usdt">USDT</option>
                    </select>
                  
                </div>
                <div className="col-6">
                 
                    
                      <input
                        onChange={(e) => updateInputAmount(e.target.value)}
                        required=""
                        id="pay-amount"
                        type="number"
                        className="form-control"
                        placeholder="Amount in USD to Buy"
                        style={{  marginBottom: 40 }}
                      />
                
                </div>
               </div>
                   
                
              </div>
             
              <div className="exchangeInfo">
                <div id="price" className="mt-3">
                  <p>Price</p>
                  <p style={{ fontWeight: "bold" }}>
                    1 {tokenName} =
                    <b style={{ fontSize: 15, color: "#16c784" }}>
                      <span> {(usdtTohigh * 1).toFixed(4)} </span>
                    </b>
                    USDT
                    
                  </p>
                </div>




                  { /**
                    <div id="price" className="mt-3">

                      <p>
                        
                        {highAmount} Chromia = <b style={{ fontSize: 15, color: "#16c784" }}>
                          <span> {cryptoTopay} </span>
                        </b>
                        {paymentmethod}
                      
                      
                      </p>
                    
                    </div>
                    <div id="price">
                      <p>Amount to recieve  -  {highAmount} {tokenName} </p> 
                      
                    </div>
                  **/ }
                   <div>
                      <p>{tokenName} to recieve  -  {highAmount} 
                      
                      <img src="/static/img/chr_hero-ec122862-450.webp"  alt="CHR"  style={{width: '40px', height: '40px'}} />
                      
                       </p> 
                      
                    </div>
                    <hr/>
                  </div>
              

              {
                showButton ? <>
                  <button
                      onClick={() => buyButton()}
                      style={{
                         
                        
                        border: "none",
                        paddingLeft: 10,
                        paddingRight: 20
                      }}
                      className="bt text-white btn btn-primary"
                      type="button"
                      id="pay-button"
                  >
                Buy CHR
              </button>
                </>: <>


                <div className="row">
                <div className="container">
                  <div
                    className="alert alert-success"
                    id="status"
                    style={{ display: "block" }}
                  >
                    {errorMessage}
                  </div>
                </div>
              </div>
                
                
                </>
              }
              
            </div>




                  </div>
                </div>
              </div>

            </div>
           
          </div>
             
             
              
        </div>




 
        {/* Footer*/}
        
      
    </>
  );
};
export default Buy;
