
import { Link } from "react-router-dom";

const styles = {
    accordion: {
        'a, a:visited, a:hover, a:active': {
            color: '#1E1941',
            border: "0px"
          },
        textAlign: 'left', 
        backgroundColor: "#1E1941", 
        border: "0px",
    }
}
const Nav = () => {


 
  return (
        <>  
              <div className="container-fluid" >
                <div className="row">
                  <div className="col-12" style={{borderBottom: "2px solid #666666"}}>
                    <p style={{color: 'white', padding: "10px", margin: "10px"}}>
                      <b>Learn more about Chromia from our blog <a href="#" style={{color: 'white'}}>Here!</a></b>
                    </p>
                  </div>
                </div>
              </div>

              <div className="container"  style={{marginBottom: '20px'}}>
                <div className="row">
                  
                  <div className="col-12">
                  <nav className="navbar navbar-expand-lg">
                    <a className="navbar-brand" href="/" style={{margin: "10px"}}>
                      <img src="https://chromia.com/images/chromia-logo.svg" />
                    </a>
                      
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" style={{backgroundColor: '#484347'}}>
                          <span className="navbar-toggler-icon" style={{color: 'white'}}></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                          <div className="col-3 justify-content-end">
                            
                              
                          </div>
                          <div className="col-9 justify-content-end">
                            <ul className="nav justify-content-end" style={{fontWeight: 'bold'}}>
                              <li className="nav-item">
                                <a class="nav-link active" style={{color: 'white'}} aria-current="page" href="/">Home</a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" href="#" style={{color: 'white'}}>What's up</a>
                              </li>
                              <li className="nav-item">
                                <Link className="nav-link" to="/chr-token" style={{color: 'white'}}>Buy Token</Link>
                              </li>
                              <li class="nav-item">
                                <a className="nav-link" href="#" style={{color: 'white'}}>Staking</a>
                              </li>
                               
                              <li className="nav-item">
                                <a className="nav-link" href="/" style={{color: 'white'}}>Roadmap</a>
                              </li>
                              
                              <li class="nav-item">
                                <a className="nav-link" href="#" style={{color: '#484347'}}>Metaverse Grant</a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" href="#" style={{color: '#484347'}}>Innovation Lab</a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" href="#" style={{color: '#484347'}}>Whitepaper</a>
                              </li>
                            </ul> 
                              
                          </div>
                        
                        
                        </div>
                    
                    </nav>
                  </div>
                  
                </div>
              </div>
        </>
  );
};
export default Nav;
