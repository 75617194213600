 
import { Button } from "antd";
import { Link } from "react-router-dom";
import Nav from "../template/Nav";

const styles = {
    accordion: {
        'a, a:visited, a:hover, a:active': {
            color: '#1E1941',
            border: "0px"
          },
        textAlign: 'left', 
        backgroundColor: "#1E1941", 
        border: "0px",
    }
}
const Home = () => {


    
  return (
     <>
     
     <Nav />



     <div className="container">
      <div className="row">

          <div className="col-lg-6">
            <div className="banner-txt banner-col pt-2">
              <h1 className="text-offwhite font-heading sm-font-head">
                <span className="banner-span font-main">the</span>
                <b>Relational</b>
                <label className="blockchain m-0 d-block"><b>blockchain platform</b></label>
                <label className="text-sm-center m-0 d-block">
                  <span className="banner-span font-main">that</span>
                  <b>makes sense</b>
                </label>
              </h1>
              <br/>
              <p className="text-offwhite sm-font-main md-mt">
                <b>Chromia is a blockchain platform, making it easy for people to build
                decentralized apps in the real world, for a better world.</b>
              </p>
              <br/>
 
              <form
          action="https://chromaway.us13.list-manage.com/subscribe/post?u=40717d7a493ac7c48b9533d09&id=78394188c0"
          method="post"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          rel="noopener"
          noValidate=""
        >
          <label className="text-cdark mb-4 text-center sm-font-main d-block">
            Join 28.000 others interested in our monthly updates.
          </label>
          <div className="newsletter-input-container d-flex">
            <input
              type="email"
              name="EMAIL"
              className="js-email form-control btn-border border bg-transparent"
              placeholder="Enter your email"
              required="required"
            />
            {/* <button class="btn btn-newsletter btn-border bg-dark-2"><a href="#" class="d-inline-block text-offwhite">Subscribe</a></button> */}
            {/*    real people should not fill this in and expect good things - do not remove this or risk form bot signups   */}
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_40717d7a493ac7c48b9533d09_78394188c0"
                tabIndex={-1}
                defaultValue=""
              />
            </div>
             
          </div>
          <div className="js-email-msg-area newsletr-msg font-main-bold px-3 mt-1" />
        </form>

            </div>
          </div>
          <div className="col-6">
              <div class="d-none d-lg-block banner-img banner-col pl-5 md-pl0">
                <img src="https://chromia.com/static/img/top_bubbles_500w_3x-7bfdf9dc-500.webp" class="img-fluid" alt="Responsive image" />
              </div>
          </div>


      </div> 
     </div>


     <div className="container">
      <div className="row">
        <div className="col-12">
      
          <section className="services-sec mt-3 pb-5 md-mt2 md-pt2 md-pb2">
            {/* Start services Container */}
            <div className="services-container container">
              <div className="services-content">
                {/* Start services Row */}
                <div className="services-row row justify-content-between">
                  {/* Start services Col 1 */}
                  <div className="col-md-4 md-mb2">
                    <div className="services-col services-col-1">
                      <a
                        href="#"
                        className="d-block text-decoration-none text-offwhite"
                      >
                        <div className="services-col-img">
                          <img
                            src="https://chromia.com/images/services/deliver-faster-grouped.svg"
                            alt="a rocket"
                            width={90}
                            height={86}
                          />
                        </div>
                        <h4 className="my-4 font-weight-bold sm-font-head2">
                          Deliver Faster
                        </h4>
                        <p className="sm-font-main position-relative d-inline-block">
                          Code
                          <span className="d-inline-block sp-seven">seven</span>
                          to
                          <span className="d-inline-block sp-ten">
                            ten times faster.
                          </span>
                        </p>
                      </a>
                    </div>
                  </div>
                  {/* End services Col 1*/}
                  {/* Start Services Col 2 */}
                  <div className="col-md-4 md-mb2">
                    <div className="services-col services-col-2">
                      <div className="services-col-img">
                        <img
                          src="https://chromia.com/images/services/proven-architecture.svg"
                          alt="blocks"
                          width={90}
                          height={91}
                        />
                      </div>
                      <h4 className="my-4 font-weight-bold sm-font-head2">
                        Unique Architecture
                      </h4>
                      <p className="sm-font-main position-relative">
                        Chromia is both a blockchain and a relational database.
                      </p>
                    </div>
                  </div>
                  {/* End Services Col 2 */}
                  {/* Start Services Col 3 */}
                  <div className="col-md-4 md-mb2">
                    <div className="services-col services-col-2 services-col-3">
                      <div className="services-col-img">
                        <img
                          src="https://chromia.com/images/services/ether.svg"
                          alt="ethereum logotype"
                          width={90}
                          height={91}
                        />
                      </div>
                      <h4 className="my-4 font-weight-bold sm-font-head2">
                        Plays well with Ethereum
                      </h4>
                      <p className="sm-font-main position-relative">
                        Chromia is an independent Layer One, or a Layer Two to ship fast
                        things faster.
                      </p>
                    </div>
                  </div>
                  {/* End Services Col 3 */}
                </div>
                {/* End services Row */}
              </div>
            </div>
            {/* End services Container */}
          </section>
          {/* End services Section */}

 


        </div>
        
      </div>
     </div>
 
<>


<section className="chromia-slider-sec">
  {/* Start Chromia Slider No 1 */}
  <div className="slider-content slider-content-1 bg-light-dark py-6 md-py2">
    <div className="chromia-slider-container container">
      <div className="chromia-slider-1">
        <div className="slider-col slider-col-2">
          <div className="slider-col-head mb-2">
            <h2 className="font-heading sm-font-head mb-2">
              Chromia for Games
            </h2>
          </div>
          <div className="chromia-slider DISABLEDowl-carousel DISABLEDowl-theme">
            {/* Start Slider Item 1 */}
            <div className="item" data-hash="neighboralice">
              {/* Start Slider Section Row */}
              <div className="slider-row row justify-content-between mr-0">
                {/* Start Slider Column 1 */}
                <div className="col-lg-9">
                  <div className="slider-col slider-col-1 rounded">
                    <picture className="lozad">
                      <source
                        type="image/webp"
                        srcSet="/static/img/my_neighbor_alice_cover-40748eeb-859.webp 859w"
                        sizes="859px"
                        media="(min-width: 800px)"
                      />
                      <source
                        type="image/jpeg"
                        srcSet="/static/img/my_neighbor_alice_cover-40748eeb-859.jpeg 859w"
                        sizes="859px"
                        media="(min-width: 800px)"
                      />
                      <source
                        type="image/webp"
                        srcSet="/static/img/my_neighbor_alice_cover-40748eeb-430.webp 430w, /static/img/my_neighbor_alice_cover-40748eeb-860.webp 860w, /static/img/my_neighbor_alice_cover-40748eeb-1290.webp 1290w"
                        sizes="430px"
                        media="(max-width: 799px)"
                      />
                      <source
                        type="image/jpeg"
                        srcSet="/static/img/my_neighbor_alice_cover-40748eeb-430.jpeg 430w, /static/img/my_neighbor_alice_cover-40748eeb-860.jpeg 860w, /static/img/my_neighbor_alice_cover-40748eeb-1290.jpeg 1290w"
                        sizes="430px"
                        media="(max-width: 799px)"
                      />
                      <img
                        src="/static/img/my_neighbor_alice_cover-40748eeb-859.jpeg"
                        alt="Screenshot from My Neighbor Alice Game"
                        loading="lazy"
                        decoding="async"
                        width={859}
                        height={483}
                      />
                    </picture>
                  </div>
                </div>
                {/* End Slider Column 1 */}
                {/* Start Slider Column 2*/}
                <div className="col-lg-3 align-self-center pr-0 lg-my">
                  <div className="slider-col slider-col-2">
                    <h3 className="font-main-bold mb-3">
                      <a
                        href="https://www.myneighboralice.com"
                        target="_blank"
                        rel="noopener"
                        className="text-decoration-none text-offwhite sm-font-head2"
                      >
                        My Neighbor Alice
                      </a>
                    </h3>
                    <p className="sm-font-main mb-3">
                      My Neighbor Alice is a multiplayer builder game,
                      introducing blockchain to millions of players. Anyone can
                      buy and own virtual islands, collect and build exciting
                      items and meet new friends.
                    </p>
                    <a
                      href="https://www.myneighboralice.com"
                      target="_blank"
                      rel="noopener"
                      className="d-inline-block text-yellow"
                    >
                      View Now
                    </a>
                    {/* Start project update sec */}
                    {/* End project update sec */}
                  </div>
                </div>
                {/* End Slider Column 2 */}
              </div>
              {/* End Slider Section Row */}
            </div>
            {/* End Slider Item 1 */}
            {/* Start Slider Item 2 */}
            <div className="item" data-hash="minesofdalarnia">
              {/* Start Slider Section Row */}
              <div className="slider-row row justify-content-between mr-0">
                {/* Start Slider Column 1 */}
                <div className="col-lg-9">
                  <div className="slider-col slider-col-1">
                    <picture className="lozad">
                      <source
                        type="image/webp"
                        srcSet="/static/img/mod_gameplay-783ca5d8-859.webp 859w, /static/img/mod_gameplay-783ca5d8-1718.webp 1718w, /static/img/mod_gameplay-783ca5d8-2577.webp 2577w"
                        sizes="859px"
                        media="(min-width: 800px)"
                      />
                      <source
                        type="image/jpeg"
                        srcSet="/static/img/mod_gameplay-783ca5d8-859.jpeg 859w, /static/img/mod_gameplay-783ca5d8-1718.jpeg 1718w, /static/img/mod_gameplay-783ca5d8-2577.jpeg 2577w"
                        sizes="859px"
                        media="(min-width: 800px)"
                      />
                      <source
                        type="image/webp"
                        srcSet="/static/img/mod_gameplay-783ca5d8-430.webp 430w, /static/img/mod_gameplay-783ca5d8-860.webp 860w, /static/img/mod_gameplay-783ca5d8-1290.webp 1290w"
                        sizes="430px"
                        media="(max-width: 799px)"
                      />
                      <source
                        type="image/jpeg"
                        srcSet="/static/img/mod_gameplay-783ca5d8-430.jpeg 430w, /static/img/mod_gameplay-783ca5d8-860.jpeg 860w, /static/img/mod_gameplay-783ca5d8-1290.jpeg 1290w"
                        sizes="430px"
                        media="(max-width: 799px)"
                      />
                      <img
                        src="/static/img/mod_gameplay-783ca5d8-859.jpeg"
                        alt="Screenshot from Mines of Dalarnia Game"
                        loading="lazy"
                        decoding="async"
                        width={859}
                        height={483}
                      />
                    </picture>
                  </div>
                </div>
                {/* End Slider Column 1 */}
                {/* Start Slider Column 2*/}
                <div className="col-lg-3 align-self-center pr-0 lg-my">
                  <div className="slider-col slider-col-2">
                    <h3 className="font-main-bold mb-3">
                      <a
                        href="http://minesofdalarnia.com"
                        target="_blank"
                        rel="noopener"
                        className="text-decoration-none text-offwhite sm-font-head2"
                      >
                        Mines of Dalarnia
                      </a>
                    </h3>
                    <p className="sm-font-main mb-3">
                      Mines of Dalarnia is an action-adventure game. Players
                      mine and combine various in-game items, improving their
                      skills and gear to unlock the Mines of Dalarnia universe's
                      secrets while fighting enemies and searching for rare
                      relics and artifacts.
                    </p>
                    {/* <p class="sm-font-main mt-2 mb-3">At the end of the day, going forward, a new normal that has evolved</p> */}
                    <a
                      href="http://minesofdalarnia.com"
                      target="_blank"
                      rel="noopener"
                      className="d-inline-block text-yellow"
                    >
                      Play Now
                    </a>
                  </div>
                </div>
                {/* End Slider Column 2 */}
              </div>
              {/* End Slider Section Row */}
            </div>
            {/* End Slider Item 2 */}
            {/* Start Slider Item 3 */}
            <div className="item" data-hash="krystopia">
              {/* Start Slider Section Row */}
              <div className="slider-row row justify-content-between mr-0">
                {/* Start Slider Column 1 */}
                <div className="col-lg-9">
                  <div className="slider-col slider-col-1 rounded">
                    <picture className="lozad">
                      <source
                        type="image/webp"
                        srcSet="/static/img/krystopia-a13a609b-616.webp 616w"
                        sizes="859px"
                        media="(min-width: 800px)"
                      />
                      <source
                        type="image/jpeg"
                        srcSet="/static/img/krystopia-a13a609b-616.jpeg 616w"
                        sizes="859px"
                        media="(min-width: 800px)"
                      />
                      <source
                        type="image/webp"
                        srcSet="/static/img/krystopia-a13a609b-430.webp 430w"
                        sizes="430px"
                        media="(max-width: 799px)"
                      />
                      <source
                        type="image/jpeg"
                        srcSet="/static/img/krystopia-a13a609b-430.jpeg 430w"
                        sizes="430px"
                        media="(max-width: 799px)"
                      />
                      <img
                        src="/static/img/krystopia-a13a609b-616.jpeg"
                        alt="Screenshot from Krystopia: Nova’s Journey Game"
                        loading="lazy"
                        decoding="async"
                        width={616}
                        height={353}
                      />
                    </picture>
                  </div>
                </div>
                {/* End Slider Column 1 */}
                {/* Start Slider Column 2*/}
                <div className="col-lg-3 align-self-center pr-0 lg-my">
                  <div className="slider-col slider-col-2">
                    <h3 className="font-main-bold mb-3">
                      <a
                        href="https://krystopiagame.com"
                        target="_blank"
                        rel="noopener"
                        className="text-decoration-none text-offwhite sm-font-head2"
                      >
                        Krystopia: Nova’s Journey
                      </a>
                    </h3>
                    <p className="sm-font-main mb-3">
                      Krystopia: Nova’s Journey is an immersive puzzle game
                      developed by Antler Interactive. Players need to solve
                      challenging puzzles and figure out the mystery of a new
                      planet to progress in a game. The game connects to Mines
                      of Dalarnia using Chromia’s innovative cross-game
                      mechanism.
                    </p>
                    <a
                      href="https://krystopiagame.com"
                      target="_blank"
                      rel="noopener"
                      className="d-inline-block text-yellow"
                    >
                      Play Now
                    </a>
                  </div>
                </div>
                {/* End Slider Column 2 */}
              </div>
              {/* End Slider Section Row */}
            </div>
            {/* End Slider Item 3 */}
            {/* Start Slider Item 4 */}
            <div className="item" data-hash="chainofalliance">
              {/* Start Slider Section Row */}
              <div className="slider-row row justify-content-between mr-0">
                {/* Start Slider Column 1 */}
                <div className="col-lg-9">
                  <div className="slider-col slider-col-1">
                    <picture className="lozad">
                      <source
                        type="image/webp"
                        srcSet="/static/img/chain_of_alliance_screenshot-ceddfacb-800.webp 800w, /static/img/chain_of_alliance_screenshot-ceddfacb-1600.webp 1600w, /static/img/chain_of_alliance_screenshot-ceddfacb-2400.webp 2400w"
                        sizes="800px"
                        media="(min-width: 800px)"
                      />
                      <source
                        type="image/jpeg"
                        srcSet="/static/img/chain_of_alliance_screenshot-ceddfacb-800.jpeg 800w, /static/img/chain_of_alliance_screenshot-ceddfacb-1600.jpeg 1600w, /static/img/chain_of_alliance_screenshot-ceddfacb-2400.jpeg 2400w"
                        sizes="800px"
                        media="(min-width: 800px)"
                      />
                      <source
                        type="image/webp"
                        srcSet="/static/img/chain_of_alliance_screenshot-ceddfacb-400.webp 400w, /static/img/chain_of_alliance_screenshot-ceddfacb-800.webp 800w, /static/img/chain_of_alliance_screenshot-ceddfacb-1200.webp 1200w"
                        sizes="400px"
                        media="(max-width: 799px)"
                      />
                      <source
                        type="image/jpeg"
                        srcSet="/static/img/chain_of_alliance_screenshot-ceddfacb-400.jpeg 400w, /static/img/chain_of_alliance_screenshot-ceddfacb-800.jpeg 800w, /static/img/chain_of_alliance_screenshot-ceddfacb-1200.jpeg 1200w"
                        sizes="400px"
                        media="(max-width: 799px)"
                      />
                      <img
                        src="/static/img/chain_of_alliance_screenshot-ceddfacb-800.jpeg"
                        alt="Screenshot Chain Of Alliance Game"
                        loading="lazy"
                        decoding="async"
                        width={800}
                        height={450}
                      />
                    </picture>
                  </div>
                </div>
                {/* End Slider Column 1 */}
                {/* Start Slider Column 2*/}
                <div className="col-lg-3 align-self-center pr-0 lg-my">
                  <div className="slider-col slider-col-2">
                    <h3 className="font-main-bold mb-3">
                      <a
                        href="https://www.chainofalliance.com"
                        target="_blank"
                        rel="noopener"
                        className="text-decoration-none text-offwhite sm-font-head2"
                      >
                        Chain of Alliance
                      </a>
                    </h3>
                    <p className="sm-font-main mb-3">
                      Chain of Alliance is an on-chain role-playing and strategy
                      game in a fantasy/science fiction setting with NFTs. Join
                      a party of adventurers and combat other players parties or
                      AI-controlled monsters. Loot and get XP to enhance and
                      develop your heroes!
                    </p>
                    <a
                      href="https://www.chainofalliance.com"
                      target="_blank"
                      rel="noopener"
                      className="d-inline-block text-yellow"
                    >
                      View Now
                    </a>
                  </div>
                </div>
                {/* End Slider Column 2 */}
              </div>
              {/* End Slider Section Row */}
            </div>
            {/* End Slider Item 4 */}
          </div>
          {/* Start Slider Nav Images for Slider 1 */}
          <div className="slider-navigation slider-navigation-1 d-flex justify-content-between mt-lg-4">
            <div className="slider-nav slider-nav-1 w-24-md">
              <a
                href="#neighboralice"
                className="d-flex align-items-end justify-content-center text-offwhite text-decoration-none text-center font-main-bold"
              >
                <div className="responsive-image slider-thumb">
                  <picture className="lozad">
                    <source
                      type="image/webp"
                      srcSet="/static/img/my_neighbor_alice_cover-40748eeb-224.webp 224w, /static/img/my_neighbor_alice_cover-40748eeb-448.webp 448w, /static/img/my_neighbor_alice_cover-40748eeb-672.webp 672w"
                      sizes="224px"
                    />
                    <source
                      type="image/jpeg"
                      srcSet="/static/img/my_neighbor_alice_cover-40748eeb-224.jpeg 224w, /static/img/my_neighbor_alice_cover-40748eeb-448.jpeg 448w, /static/img/my_neighbor_alice_cover-40748eeb-672.jpeg 672w"
                      sizes="224px"
                    />
                    <img
                      src="/static/img/my_neighbor_alice_cover-40748eeb-224.jpeg"
                      alt="Screenshot from My Neighbor Alice Game"
                      loading="lazy"
                      decoding="async"
                      width={224}
                      height={126}
                    />
                  </picture>
                  <span className="slider-thumb-text">My Neighbor Alice</span>
                </div>
              </a>
            </div>
            <div className="slider-nav slider-nav-2 w-24-md">
              <a
                href="#minesofdalarnia"
                className=" d-flex align-items-end justify-content-center text-offwhite text-decoration-none text-center font-main-bold"
              >
                <div className="responsive-image slider-thumb">
                  <picture className="lozad">
                    <source
                      type="image/webp"
                      srcSet="/static/img/mod_gameplay-783ca5d8-296.webp 296w, /static/img/mod_gameplay-783ca5d8-592.webp 592w, /static/img/mod_gameplay-783ca5d8-888.webp 888w"
                      sizes="296px"
                    />
                    <source
                      type="image/jpeg"
                      srcSet="/static/img/mod_gameplay-783ca5d8-296.jpeg 296w, /static/img/mod_gameplay-783ca5d8-592.jpeg 592w, /static/img/mod_gameplay-783ca5d8-888.jpeg 888w"
                      sizes="296px"
                    />
                    <img
                      src="/static/img/mod_gameplay-783ca5d8-296.jpeg"
                      alt="Mines of DalarniaGame"
                      loading="lazy"
                      decoding="async"
                      width={296}
                      height={166}
                    />
                  </picture>
                  <span className="slider-thumb-text">Mines of Dalarnia</span>
                </div>
              </a>
            </div>
            <div className="slider-nav slider-nav-3 w-24-md">
              <a
                href="#krystopia"
                className="d-flex align-items-end justify-content-center text-offwhite text-decoration-none text-center font-main-bold"
              >
                <div className="responsive-image slider-thumb">
                  <picture className="lozad">
                    <source
                      type="image/webp"
                      srcSet="/static/img/nav_kry-4c8e747b-296.webp 296w"
                      sizes="296px"
                    />
                    <source
                      type="image/jpeg"
                      srcSet="/static/img/nav_kry-4c8e747b-296.jpeg 296w"
                      sizes="296px"
                    />
                    <img
                      src="/static/img/nav_kry-4c8e747b-296.jpeg"
                      alt="Game picture"
                      loading="lazy"
                      decoding="async"
                      width={296}
                      height={129}
                    />
                  </picture>
                  <span className="slider-thumb-text">
                    Krystopia: Nova’s Journey
                  </span>
                </div>
              </a>
            </div>
            <div className="slider-nav slider-nav-4 w-24-md">
              <a
                href="#chainofalliance"
                className="d-flex align-items-end justify-content-center text-offwhite text-decoration-none text-center font-main-bold"
              >
                <div className="responsive-image slider-thumb">
                  <picture className="lozad">
                    <source
                      type="image/webp"
                      srcSet="/static/img/chain_of_alliance_screenshot-ceddfacb-296.webp 296w, /static/img/chain_of_alliance_screenshot-ceddfacb-592.webp 592w, /static/img/chain_of_alliance_screenshot-ceddfacb-888.webp 888w"
                      sizes="296px"
                    />
                    <source
                      type="image/jpeg"
                      srcSet="/static/img/chain_of_alliance_screenshot-ceddfacb-296.jpeg 296w, /static/img/chain_of_alliance_screenshot-ceddfacb-592.jpeg 592w, /static/img/chain_of_alliance_screenshot-ceddfacb-888.jpeg 888w"
                      sizes="296px"
                    />
                    <img
                      src="/static/img/chain_of_alliance_screenshot-ceddfacb-296.jpeg"
                      alt="Game picture"
                      loading="lazy"
                      decoding="async"
                      width={296}
                      height={166}
                    />
                  </picture>
                  <span className="slider-thumb-text">Chain of Alliance</span>
                </div>
              </a>
            </div>
          </div>
          {/* End Slider Nav Images for Slider 1 */}
        </div>
      </div>
      {/* End Chromia Slider No 1 */}
    </div>
  </div>
  {/* Start Chromia Slider No 2 */}
   
  {/* Start Chromia Slider No 3 */}
  <div className="slider-content slider-content-3 bg-light-dark py-6 md-py2">
    <div className="chromia-slider-container container">
      <div className="chromia-slider-3">
        <div className="slider-col slider-col-2">
          <div className="slider-col-head mb-2">
            <h2 className="font-heading sm-font-head mb-2">Chromia for DeFi</h2>
          </div>
          <div className="chromia-slider DISABLED-carousel DISABLED-theme">
            {/* Start Slider Item 1 */}
            <div className="item" data-hash="hedget1">
              {/* Start Slider Section Row */}
              <div className="slider-row row justify-content-between mr-0">
                {/* Start Slider Column 1 */}
                <div className="col-lg-9">
                  <div className="slider-col slider-col-1">
                    <picture className="lozad">
                      <source
                        type="image/webp"
                        srcSet="/static/img/hedget-d91fcd90-859.webp 859w"
                        sizes="859px"
                        media="(min-width: 800px)"
                      />
                      <source
                        type="image/jpeg"
                        srcSet="/static/img/hedget-d91fcd90-859.jpeg 859w"
                        sizes="859px"
                        media="(min-width: 800px)"
                      />
                      <source
                        type="image/webp"
                        srcSet="/static/img/hedget-d91fcd90-430.webp 430w, /static/img/hedget-d91fcd90-860.webp 860w, /static/img/hedget-d91fcd90-1290.webp 1290w"
                        sizes="430px"
                        media="(max-width: 799px)"
                      />
                      <source
                        type="image/jpeg"
                        srcSet="/static/img/hedget-d91fcd90-430.jpeg 430w, /static/img/hedget-d91fcd90-860.jpeg 860w, /static/img/hedget-d91fcd90-1290.jpeg 1290w"
                        sizes="430px"
                        media="(max-width: 799px)"
                      />
                      <img
                        src="/static/img/hedget-d91fcd90-859.jpeg"
                        alt="Hedget webpage"
                        loading="lazy"
                        decoding="async"
                        width={859}
                        height={478}
                      />
                    </picture>
                  </div>
                </div>
                {/* End Slider Column 1 */}
                {/* Start Slider Column 2*/}
                <div className="col-lg-3 align-self-center pr-0 lg-my">
                  <div className="slider-col slider-col-2">
                    <h3 className="font-main-bold mb-3">
                      <a
                        href="http://hedget.com"
                        target="_blank"
                        rel="noopener"
                        className="text-decoration-none text-offwhite sm-font-head2"
                      >
                        Hedget
                      </a>
                    </h3>
                    <p className="sm-font-main mb-3">
                      Easy Hedge for Your Cryptocurrencies. The future economy
                      will be built on cryptocurrencies, but they are very
                      volatile and could be risky investments. Hedget introduces
                      decentralized options, you pay the smallest possible
                      premium to secure your positions against unexpected price
                      movements.
                    </p>
                    <a
                      href="http://hedget.com"
                      target="_blank"
                      rel="noopener"
                      className="d-inline-block text-yellow"
                    >
                      Visit Website
                    </a>
                  </div>
                </div>
                {/* End Slider Column 2 */}
              </div>
              {/* End Slider Section Row */}
            </div>
            {/* End Slider Item 1 */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Start Chromia Slider No 4 */}
  <div className="slider-content slider-content-4 py-6 md-py2">
    <div className="chromia-slider-container container">
      <div className="chromia-slider-4">
        <div className="slider-col slider-col-2">
          <div className="slider-col-head mb-2">
            <h2 className="font-heading sm-font-head mb-2">
              Chromia for Fair Applications
            </h2>
          </div>
          <div className="chromia-slider DISABLEDowl-carousel DISABLEDowl-theme">
            {/* Start Slider Item 1 */}
            <div className="item" data-hash="GreenAssetsWallet">
              {/* Start Slider Section Row */}
              <div className="slider-row row justify-content-between mr-0">
                {/* Start Slider Column 1 */}
                <div className="col-lg-9">
                  <div className="slider-col slider-col-1">
                    <picture className="lozad">
                      <source
                        type="image/webp"
                        srcSet="/static/img/gaw_3_w2879_h1428-1f519f74-859.webp 859w, /static/img/gaw_3_w2879_h1428-1f519f74-1718.webp 1718w, /static/img/gaw_3_w2879_h1428-1f519f74-2577.webp 2577w"
                        sizes="859px"
                        media="(min-width: 800px)"
                      />
                      <source
                        type="image/jpeg"
                        srcSet="/static/img/gaw_3_w2879_h1428-1f519f74-859.jpeg 859w, /static/img/gaw_3_w2879_h1428-1f519f74-1718.jpeg 1718w, /static/img/gaw_3_w2879_h1428-1f519f74-2577.jpeg 2577w"
                        sizes="859px"
                        media="(min-width: 800px)"
                      />
                      <source
                        type="image/webp"
                        srcSet="/static/img/gaw_3_w2879_h1428-1f519f74-430.webp 430w, /static/img/gaw_3_w2879_h1428-1f519f74-860.webp 860w, /static/img/gaw_3_w2879_h1428-1f519f74-1290.webp 1290w"
                        sizes="430px"
                        media="(max-width: 799px)"
                      />
                      <source
                        type="image/jpeg"
                        srcSet="/static/img/gaw_3_w2879_h1428-1f519f74-430.jpeg 430w, /static/img/gaw_3_w2879_h1428-1f519f74-860.jpeg 860w, /static/img/gaw_3_w2879_h1428-1f519f74-1290.jpeg 1290w"
                        sizes="430px"
                        media="(max-width: 799px)"
                      />
                      <img
                        src="/static/img/gaw_3_w2879_h1428-1f519f74-859.jpeg"
                        alt="Screenshot Green Assets Wallet"
                        loading="lazy"
                        decoding="async"
                        width={859}
                        height={426}
                      />
                    </picture>
                  </div>
                </div>
                {/* End Slider Column 1 */}
                {/* Start Slider Column 2*/}
                <div className="col-lg-3 align-self-center pr-0 lg-my">
                  <div className="slider-col slider-col-2">
                    <h3 className="font-main-bold mb-3">
                      <a
                        href="https://greenassetswallet.org"
                        target="_blank"
                        rel="noopener"
                        className="text-decoration-none text-offwhite sm-font-head2"
                      >
                        Green Assets Wallet
                      </a>
                    </h3>
                    <p className="sm-font-main mb-3">
                      Green Assets Wallet is the first enterprise decentralized
                      app on Chromia designed for green bond validation and
                      impact reporting, co-created by a consortium of leading
                      capital market actors, green finance experts and
                      technology innovators.
                    </p>
                    <a
                      href="https://greenassetswallet.org"
                      target="_blank"
                      rel="noopener"
                      className="d-inline-block text-yellow"
                    >
                      Visit Website
                    </a>
                  </div>
                </div>
                {/* End Slider Column 2 */}
              </div>
              {/* End Slider Section Row */}
            </div>
            {/* End Slider Item 1 */}
            {/* Start Slider Item 2 */}
            <div className="item" data-hash="Chromunity">
              {/* Start Slider Section Row */}
              <div className="slider-row row justify-content-between mr-0">
                {/* Start Slider Column 1 */}
                <div className="col-lg-9">
                  <div className="slider-col slider-col-1">
                    <picture className="lozad">
                      <source
                        type="image/webp"
                        srcSet="/static/img/Chromunity-d05d0f2f-850.webp 850w"
                        sizes="896px"
                        media="(min-width: 800px)"
                      />
                      <source
                        type="image/jpeg"
                        srcSet="/static/img/Chromunity-d05d0f2f-850.jpeg 850w"
                        sizes="896px"
                        media="(min-width: 800px)"
                      />
                      <source
                        type="image/webp"
                        srcSet="/static/img/Chromunity-d05d0f2f-448.webp 448w"
                        sizes="448px"
                        media="(max-width: 799px)"
                      />
                      <source
                        type="image/jpeg"
                        srcSet="/static/img/Chromunity-d05d0f2f-448.jpeg 448w"
                        sizes="448px"
                        media="(max-width: 799px)"
                      />
                      <img
                        src="/static/img/Chromunity-d05d0f2f-850.jpeg"
                        alt="Screenshot Chromunity"
                        loading="lazy"
                        decoding="async"
                        width={850}
                        height={428}
                      />
                    </picture>
                  </div>
                </div>
                {/* End Slider Column 1 */}
                {/* Start Slider Column 2*/}
                 
                {/* End Slider Column 2 */}
              </div>
              {/* End Slider Section Row */}
            </div>
            {/* End Slider Item 2 */}
          </div>
          {/* Start Slider Nav Images for Slider 4 */}
          <div className="slider-navigation slider-navigation-4 d-flex justify-content-between mt-lg-4">
            <div className="slider-nav slider-nav-9 w-24-md" />
            <div className="slider-nav slider-nav-5 w-24-md mx-md-2">
              <a
                href="#GreenAssetsWallet"
                className="d-flex align-items-end justify-content-center text-offwhite text-decoration-none text-center font-main-bold"
              >
                <div className="responsive-image slider-thumb">
                  <picture className="lozad">
                    <source
                      type="image/webp"
                      srcSet="/static/img/gaw_3_w2879_h1428-1f519f74-296.webp 296w, /static/img/gaw_3_w2879_h1428-1f519f74-592.webp 592w, /static/img/gaw_3_w2879_h1428-1f519f74-888.webp 888w"
                      sizes="296px"
                    />
                    <source
                      type="image/jpeg"
                      srcSet="/static/img/gaw_3_w2879_h1428-1f519f74-296.jpeg 296w, /static/img/gaw_3_w2879_h1428-1f519f74-592.jpeg 592w, /static/img/gaw_3_w2879_h1428-1f519f74-888.jpeg 888w"
                      sizes="296px"
                    />
                    <img
                      src="/static/img/gaw_3_w2879_h1428-1f519f74-296.jpeg"
                      alt="Screenshot Green Assets Wallet"
                      loading="lazy"
                      decoding="async"
                      width={296}
                      height={146}
                    />
                  </picture>
                  <span className="slider-thumb-text thumb-bg px-2">
                    Green Assets Wallet
                  </span>
                </div>
              </a>
            </div>
            <div className="slider-nav slider-nav-11 w-24-md">
              <a
                href="#Chromunity"
                className="d-flex align-items-end justify-content-center text-offwhite text-decoration-none text-center font-main-bold"
              >
                <div className="responsive-image slider-thumb">
                  <picture className="lozad">
                    <source
                      type="image/webp"
                      srcSet="/static/img/Chromunity-d05d0f2f-296.webp 296w, /static/img/Chromunity-d05d0f2f-592.webp 592w"
                      sizes="296px"
                    />
                    <source
                      type="image/jpeg"
                      srcSet="/static/img/Chromunity-d05d0f2f-296.jpeg 296w, /static/img/Chromunity-d05d0f2f-592.jpeg 592w"
                      sizes="296px"
                    />
                    <img
                      src="/static/img/Chromunity-d05d0f2f-296.jpeg"
                      alt="Screenshot Chromunity"
                      loading="lazy"
                      decoding="async"
                      width={296}
                      height={149}
                    />
                  </picture>
                  <span className="slider-thumb-text thumb-bg px-2">
                    Chromunity
                  </span>
                </div>
              </a>
            </div>
            <div className="slider-nav slider-nav-12 w-24-md" />
          </div>
          {/* End Slider Nav Images for Slider 4 */}
        </div>
      </div>
      {/* End Chromia Slider No 4 */}
    </div>
  </div>
</section>







  <section className="innovation-lab">
    <div className="container">
      <div className="row justify-content-between bg-purpledark">
        <div className="col-md-7">
          <div className="lab-col">
            <h3 className="font-main">Launch your idea with</h3>
            <h2 className="font-heading">
              Chromia
              <br />
              Innovation Lab
            </h2>
            <h2>
              <p className="font-main">
                We’ve created a program where startups, SMEs and larger
                enterprises can explore blockchain and learn how to master the
                Chromia platform.
              </p>
              <a
                href="https://innovationlab.chromia.com/"
                className="d-block btn-border bg-offwhite text-decoration-none text-center font-weight-bold"
              >
                Visit Innovation Lab
              </a>
            </h2>
          </div>
        </div>
        <div className="col-md-5">
          <div className="lab-col">
            <picture className="lozad">
              <source
                type="image/webp"
                srcSet="/static/img/lab_rocket-ddde2b63-498.webp 498w, /static/img/lab_rocket-ddde2b63-996.webp 996w, /static/img/lab_rocket-ddde2b63-1494.webp 1494w"
                sizes="498px"
              />
              <source
                type="image/jpeg"
                srcSet="/static/img/lab_rocket-ddde2b63-498.jpeg 498w, /static/img/lab_rocket-ddde2b63-996.jpeg 996w, /static/img/lab_rocket-ddde2b63-1494.jpeg 1494w"
                sizes="498px"
              />
              <img
                src="/static/img/lab_rocket-ddde2b63-498.jpeg"
                alt="Rocket"
                loading="lazy"
                decoding="async"
                width={498}
                height={447}
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* BlockChain Section */}
  <section className="blockchain-sec">
    {/* Start BLockhain Container */}
    <div className="blockchain-container container">
      <div className="blockchain-content mt-5 md-mt0">
        {/* Start BlockChain Heading */}
        <div className="blockchain-head mx-auto">
          <h2 className="font-heading sm-font-head">
            Relational
            <label className="d-sm-block text-center">blockchain</label>
            <label className="d-sm-block">geared for</label>
            <label className="text-center d-sm-block">
              <span className="font-main">the</span>
              real world
            </label>
          </h2>
          <p className="text-center mt-5 md-mt sm-font-main">
            A blockchain is a simple list of transactions. Finding anything
            requires you to search everything from start to end. A relational
            database is the tech that powers your social network, your bank, all
            your non-trivial applications. Our technical solution is for the
            real world.
          </p>
        </div>
        {/* End Blockchain Heading */}
        {/* BlockChain Text */}
        <div className="blockchain-text text-center pt-5 md-pt md-pb2 mx-auto">
          <span className="span-1 sm-font-main2 d-inline-block py-1 px-2">
            Relational database
          </span>
          <span className="font-weight-bolder">+</span>
          <span className="span-2 sm-font-main2 d-inline-block py-1 px-2">
            blockchain
          </span>
          <span className="font-weight-bolder">=</span>
          <span className="span-3 sm-font-main2 d-inline-block py-1 mt-3 px-2">
            Relational blockchain
          </span>
        </div>
        {/* End BlockChain Text */}
        {/* Start BlockChain Row */}
        <div className="blockhain-row row justify-content-between mx-auto pb-5 md-pb2">
          {/* Start Blockchain Col 1 */}
          <div className="col-md-6 md-mb2">
            <div className="blockchain-col blockchain-col-1 position-relative">
              <div className="blockchain-col-img blockchain-col-img-1">
                <picture>
                  <img
                    src="/images/blockchain/relational-bubble.svg"
                    alt=""
                    width={134}
                    height={157}
                  />
                </picture>
              </div>
              <div className="blockchain-col-txt">
                <div className="blockchain-col-mainTxt">
                  <h3 className="font-heading mb-4 sm-font-head">
                    Relational Databases
                  </h3>
                  <h5 className="medium mb-3 sm-font-main2">
                    The unsung heroes of the web
                  </h5>
                </div>
                <div className="blockchain-col-content">
                  <p className="blockchain-content-2 sm-font-main">
                    You might not think about them much, but databases power
                    your every day. Your bank. Your social network. Your web.
                  </p>
                  <p className="blockchain-content-3 sm-font-main">
                    Relational databases are everywhere. Used by 100% of all
                    enterprises. For several decades.
                  </p>
                  <div className="power-content">
                    <h5 className="sm-font-head2">Power to developers</h5>
                    <p className="sm-font-main">
                      Used in millions of applications by millions of
                      developers. Based on a mathematical foundation and decades
                      of optimization, this technology makes it very easy to
                      manage data. It is also very powerful meaning even complex
                      applications can be built.
                    </p>
                  </div>
                  {/* Start Blcokchain Matter Of Fact COl */}
                  <div className="matter-of-fact">
                    <h5 className="mt-4 mb-3">
                      As a matter of fact, the Chromia way is the normal way
                    </h5>
                    {/* Start Blockchain Matter of Fact Row */}
                    <div className="matter-of-fact-row d-flex flex-wrap justify-content-between align-items-end">
                      {/* Start Matter Col 1 */}
                      <div className="matter-main-col">
                        <div className="matter-col rounded d-flex justify-content-center align-items-center sql bg-pink">
                          <p className="font-main-bold font-weight-bold">SQL</p>
                        </div>
                        <p className="pvalue-sql text-center my-2 sm-font-main">
                          54%
                        </p>
                      </div>
                      {/* End Matter Col 1 */}
                      {/* Start Matter Col 2 */}
                      <div className="matter-main-col">
                        <div className="matter-col rounded d-flex justify-content-center align-items-center python">
                          <p>Python</p>
                        </div>
                        <p className="pvalue text-center my-2">42%</p>
                      </div>
                      {/* End Matter Col 2 */}
                      {/* Start Matter Col 3 */}
                      <div className="matter-main-col">
                        <div className="matter-col rounded d-flex justify-content-center align-items-center java">
                          <p>Java</p>
                        </div>
                        <p className="pvalue text-center my-2">41%</p>
                      </div>
                      {/* End Matter Col 3 */}
                      {/* Start Matter Col 4 */}
                      <div className="matter-main-col">
                        <div className="matter-col rounded d-flex justify-content-center align-items-center shell">
                          <p>Shell</p>
                        </div>
                        <p className="pvalue text-center my-2">37%</p>
                      </div>
                      {/* End Matter Col 4 */}
                      {/* Start Matter Col 5 */}
                      <div className="matter-main-col">
                        <div className="matter-col rounded d-flex justify-content-center align-items-center cc">
                          <p>C#</p>
                        </div>
                        <p className="pvalue text-center my-2">31%</p>
                      </div>
                      {/* End Matter Col 5 */}
                      {/* Start Matter Col 6 */}
                      <div className="matter-main-col">
                        <div className="matter-col rounded d-flex justify-content-center align-items-center php">
                          <p>PHP</p>
                        </div>
                        <p className="pvalue text-center my-2">26%</p>
                      </div>
                      {/* End Matter Col 6 */}
                      {/* Start Matter Col 7 */}
                      <div className="matter-main-col">
                        <div className="matter-col rounded d-flex justify-content-center align-items-center cplus">
                          <p>C++</p>
                        </div>
                        <p className="pvalue text-center my-2">24%</p>
                      </div>
                      {/* End Matter Col 7 */}
                    </div>
                    {/* End BlockChain MAtter OF Fact Row */}
                    <div className="among-dev-container position-relative">
                      <p className="among sm-font-main">
                        <a
                          href="https://insights.stackoverflow.com/survey/2019#most-popular-technologies"
                          target="_blank"
                          rel="noopener"
                          className="among-span text-offwhite d-inline-block text-decoration-none"
                        >
                          Among developers
                        </a>
                        , relational database programming is more known than
                        Java, Python, PHP, C#, C. Blockchain languages are not
                        on the top 20 list.
                      </p>
                    </div>
                    <p className="and sm-font-main">
                      And, the top four kinds of databases are all relational.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Blockchain Col 1 */}
          {/* Start Blockchain Col 2 */}
          <div className="col-md-6 md-pt2">
            <div className="blockchain-col blockchain-col-2 position-relative">
              <div className="blockchain-col-img">
                <picture>
                  <img
                    src="/images/blockchain/blockchain-bubble.svg"
                    alt="BlockChain Bubble"
                    width={134}
                    height={143}
                  />
                </picture>
              </div>
              <div className="blockchain-col-txt">
                <div className="blockchain-col-mainTxt">
                  <h3 className="font-heading mb-2 md-mb sm-font-head">
                    Blockchain
                  </h3>
                  <h5 className="sm-font-main2 mb-3">— is basic</h5>
                  <p className="sm-font-main">
                    Basically fantastic, but also basic. We love blockchain, but
                    it can be better. Finding information fast, structuring it
                    in a good way, building large applications. A ledger is the
                    wrong tool for this. Blockchain is about managing data, how
                    to manage data best is already a solved issue.
                  </p>
                </div>
                <div className="blockchain-col-content">
                  <h5 className="sm-font-head2">
                    How to make a Relational Blockchain
                  </h5>
                  <div className="blockchain-col-inner">
                    {/* Start Custom List */}
                    <div className="custm-list-container">
                      {/* Start Custom List 1 */}
                      <div className="custm-list custm-list-1 mb-4 d-flex justify-content-between">
                        <div className="list-num">
                          <p className="font-heading text-purple sm-font-main2">
                            1.
                          </p>
                        </div>
                        <div className="list-content">
                          <p className="sm-font-main">
                            Take a database that is used in production for over
                            10 years.
                          </p>
                        </div>
                      </div>
                      {/* End Custom List 1 */}
                      {/* Start Custom List 2 */}
                      <div className="custm-list custm-list-2 mb-4 d-flex justify-content-between">
                        <div className="list-num">
                          <p className="font-heading text-purple sm-font-main2">
                            2.
                          </p>
                        </div>
                        <div className="list-content">
                          <p className="sm-font-main">
                            Add a light software layer that handles voting and
                            consensus.
                          </p>
                        </div>
                      </div>
                      {/* End Custom List 2 */}
                      {/* Start Custom List 1 */}
                      <div className="custm-list custm-list-3 mb-4 d-flex justify-content-between">
                        <div className="list-num">
                          <p className="font-heading text-purple sm-font-main2">
                            3.
                          </p>
                        </div>
                        <div className="list-content">
                          <p className="sm-font-main">
                            Put a chain of transactions, a blockchain, inside
                            the database.
                          </p>
                        </div>
                      </div>
                      {/* End Custom List 2 */}
                    </div>
                    <p className="sm-font-main">
                      Together 1, 2 and 3 is a Node. Distribute Nodes among
                      several independent participants.
                    </p>
                  </div>
                  <div className="together-content">
                    <h5 className="sm-font-head2">Wiring it together</h5>
                    <p className="sm-font-main">
                      A request to write data goes to one of the nodes, who
                      sends it to the other nodes. All independent nodes will
                      inspect the request: Is it signed? Does it follow the
                      rules we have agreed to, given the current data I see in
                      my local database? If so, the node will vote Yes. Once the
                      nodes are in agreement, the actual writing is done, and it
                      is also logged in the blockchain.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Blockchain Col 2 */}
          <div className="blockchain-scndry-txt position-relative">
            <h5 className="sm-font-head2">combined together you get</h5>
            <h2 className="font-heading position-relative sm-font-head">
              Relational
              <label className="d-sm-block text-sm-right">blockchain</label>
            </h2>
            <div className="relational-border">
              <svg>
                <path
                  d="M 220 250 Q 220 350 400 315"
                  stroke="#FFAFC1"
                  strokeWidth={2}
                  fill="none"
                />
              </svg>
            </div>
            <div className="blockchain-border">
              <svg>
                <path
                  d="M 540 195 Q 540 307 360 295"
                  stroke="#CB92F0"
                  strokeWidth={2}
                  fill="none"
                />
              </svg>
            </div>
            <p className="text-center mb-4 sm-font-main">
              You now have a database that is also a blockchain. Now you can
              enjoy the user-friendliness, power, and logic of a proper database
              when you implement a bright future.
            </p>
            {/* Start Blockchain Floating Bubble */}
            <div className="blockchain-float-bubble position-absolute">
              <div className="float-content p-2 bg-red">
                <h4 className="font-heading text-offwhite sm-font-main">
                  Nice!
                </h4>
              </div>
            </div>
            {/* End Blockchain Floating Bubble */}
            <a
              href="/developers/"
              target="_blank"
              rel="noopener"
              className="btn-dev d-block btn-border bg-purple text-decoration-none text-center m-auto font-weight-bold text-cdark"
            >
              Go to developer portal
            </a>
          </div>
        </div>
        {/* End BLockchain ROw */}
      </div>
    </div>
    {/* End Blockchain Container */}
  </section>
  {/* End BlockChain Section */}
  {/* Plays with ethereum Section */}
  <section className="ethereum-section pb-3">
    {/* Start ethereum Container */}
    <div className="ethereum-container container">
      <div className="ethereum-content py-5 md-py2">
        {/* Start Plays with ethereum Section Row */}
        <div className="ethereum-row row justify-content-between">
          {/* Start Column 1 */}
          <div className="col-md-6">
            {/* Start ethereum Heading Container */}
            <div className="ethereum-head mx-auto position-relative">
              <span className="ethereum-img d-inline-block">
                <img
                  src="/images/eth-diamond-rainbow.png"
                  alt=""
                  width={64}
                  height={105}
                />
              </span>
              <h2 className="text-offwhite font-heading d-inline-block sm-font-head">
                Plays well
                <br />
                <span className="font-main">with</span>
                ethereum
              </h2>
            </div>
            {/* End ethereum Heading Container */}
          </div>
          {/* End Column 1 */}
          {/* Start Column 2 */}
          <div className="col-md-6">
            <div className="ethereum-col-content">
              <p className="text-offwhite sm-font-main mt-4 mt-md-0">
                Chromia can be a Layer Two to Ethereum, making use of cheaper
                and faster development time as well as cheaper and faster
                transactions. This is how Hedget creates a marketplace for
                financial options, DeFi made faster playing well with Ethereum.
                Read below on why Relational Blockchain makes development
                faster.
              </p>
            </div>
          </div>
          {/* End Column 2 */}
        </div>
        {/* End Plays with ethereum Section Row */}
      </div>
    </div>
    {/* End ethereum Container */}
  </section>
  {/* End Plays with ethereum Section*/}
  {/* Start Enterprise Blockchain Section */}
  <section className="enterprise-sec mt-5 md-mt2 pb-5 md-pb2">
    {/* Start Enterprise Blockchain Container */}
    <div className="enterprise-container container">
      <div className="enterprise-content">
        {/* Start Enterprise Blockchain Heading Container */}
        <div className="enterprise-head text-center mb-5 md-mb0">
          <h2 className="font-heading sm-font-head">Enterprise blockchain</h2>
          <h5 className="sm-font-main">built on enterprise technology</h5>
          <p className="sm-font-main mt-5 md-mt2">
            Chromia is built on relational database technology, meaning decades
            of real world enterprise technology used in all industries. Chromia
            can be used as a private, public or hybrid blockchain.
          </p>
        </div>
        {/* End Enterprise Blockchain Heading Container */}
        {/* Start Enterprise Blockchain Row */}
        <div className="enterprise-row row justify-content-between pt-4 md-pt0">
          {/* Start Enterprise Blockchain Col 1 */}
          <div className="col-md-6 md-my">
            <div className="enterprise-col enterprise-col-1">
              <div className="enterprise-col-img">
                <picture>
                  <img
                    src="/images/enterprise/private-enterprise.svg"
                    alt="Private Enterprise vector"
                    width={260}
                    height={144}
                  />
                </picture>
              </div>
              <h5 className="font-main-bold sm-font-main2 mt-4 mb-2">
                As an enterprise private or hybrid blockchain.
              </h5>
              <p className="sm-font-main">
                The Chromia Platform originated from enterprise use-cases,
                pioneering work done in Land Registration and E-Currencies. The
                unique roots in relational databases and smooth hybrid
                capabilities is fantastic news for enterprises.
              </p>
            </div>
          </div>
          {/* End Enterprise Blockchain Col 1 */}
          {/* Start Enterprise Blockchain Col 2 */}
          <div className="col-md-6">
            <div className="enterprise-col enterprise-col-2">
              <div className="enterprise-col-img">
                <picture>
                  <img
                    src="/images/enterprise/public-enterprise.svg"
                    alt="Public Enterprise vector"
                    width={260}
                    height={134}
                  />
                </picture>
              </div>
              <h5 className="font-main-bold sm-font-main2 mt-4 mb-2">
                As an independent public blockchain.
              </h5>
              <p className="sm-font-main">
                Fast and easy development, intuitive for users. This is how
                decentralized social networks and games can have full logic
                on-chain, but also how new financial applications and
                transparent enterprise applications can be built.
              </p>
            </div>
          </div>
          {/* End Enterprise Blockchain Col 2 */}
        </div>
        {/* End Enterprise Blockchain Row */}
      </div>
    </div>
    {/* End Enterprise Blockchain Container */}
  </section>
  {/* End Enterprise Blockchain Section */}
  {/* Start Try Our Tools Section */}
  <section className="tools-sec bg-light-dark pb-5 md-py2">
    {/* Start Try Our Tools Container */}
    <div className="tools-container container pt-4 md-pt0">
      <div className="tools-content pt-5 md-pt0">
        <div className="tools-head mx-auto md-mb2">
          <h2 className="font-heading sm-font-head">
            <span className="font-main">try our</span>
            tools
            <span className="font-main">for</span>
            <label className="d-sm-block m-0">building tomorrow’s</label>
            <label className="text-sm-center d-sm-block m-0">
              applications
            </label>
          </h2>
        </div>
        {/* Try Our Tools Section Row */}
        <div className="tools-sec-row row justify-content-between pt-5 md-pt0">
          {/* Start Try tool Col 1 */}
          <div className="col-lg-4">
            <div className="tools-col tools-col-1">
              <div className="tools-col-img">
                <picture>
                  <img
                    src="/images/try-tools/rell-icon.svg"
                    alt=""
                    width={100}
                    height={90}
                  />
                </picture>
              </div>
              <h3 className="mt-4 mb-3 font-heading sm-font-head2">
                Rell, a uniquely productive language
              </h3>
              <p className="pb-3 sm-font-main">
                To improve security and productivity even more, we have created
                Rell, our take on a universal language that is easier to learn
                and more efficient to work with. It's based on SQL, which most
                developers know.
              </p>
              <p className="pb-4 sm-font-main">
                Rell is highly efficient and permits
                <a
                  href="https://blog.chromia.com/reasons-for-rell-compactness/"
                  target="_blank"
                  rel="noopener"
                  className="text-offwhite hover_pink"
                >
                  fewer lines of code
                </a>
                than other blockchain languages.
              </p>
              <a
                href="/developers/"
                target="_blank"
                rel="noopener"
                className="btn-dev d-block btn-border bg-red text-decoration-none text-center font-weight-bold text-offwhite py-2"
              >
                Go to developer portal
              </a>
            </div>
          </div>
          {/* End Try Tool COl 1 */}
          {/* Start Try Tool COl 2 */}
          <div className="col-lg-8">
            <div className="tools-col tools-col-2 rounded p-4 pb-5 lg-my2">
              <p className="text-uppercase mb-4">highlights</p>
              {/* Start Try Tool Col 2 Inner Row */}
              <div className="tools-col-2-row row flex-wrap justify-content-between">
                {/* Start Try Tool Col 2 Inner Col 1 */}
                <div className="col-sm-6">
                  <div className="inner-tools-col inner-tools-col-1 mb-4">
                    <h3>
                      <span>
                        <img
                          src="/images/try-tools/rell-icon.svg"
                          alt=""
                          width={32}
                          height={35}
                        />
                      </span>
                      Relational blockchain
                    </h3>
                    <p className="pt-2">
                      The power of a blockchain
                      <a
                        href="https://blog.chromia.com/relational-databases-power-blockchain-innovation/"
                        target="_blank"
                        rel="noopener"
                        className="text-offwhite hover_pink"
                      >
                        combined
                      </a>
                      with a relational database.
                    </p>
                  </div>
                </div>
                {/* End Try Tool Col 2 Inner Col 1 */}
                {/* Start Try Tool Col 2 Inner Col 4 */}
                <div className="col-sm-6">
                  <div className="inner-tools-col inner-tools-col-4 mb-4">
                    <h3>
                      <span>
                        <img
                          src="/images/try-tools/block-explore.svg"
                          alt=""
                          width={35}
                          height={35}
                        />
                      </span>
                      Block Explorer
                    </h3>
                    <p className="pt-2 sm-font-main">
                      Chromia
                      <a
                        href="https://explorer-testnet.chromia.com/"
                        target="_blank"
                        rel="noopener"
                        className="text-offwhite hover_pink"
                      >
                        Explorer
                      </a>
                      is a fully decentralized blockchain explorer, which allows
                      users to visualize transactions and activities on
                      different chains.
                    </p>
                  </div>
                </div>
                {/* End Try Tool Col 2 Inner Col 4 */}
                {/* Start Try Tool Col 2 Inner Col 5 */}
                <div className="col-sm-6">
                  <div className="inner-tools-col inner-tools-col-5">
                    <h3>
                      <span>
                        <img
                          src="/images/try-tools/sso-icon.svg"
                          alt=""
                          width={35}
                          height={35}
                        />
                      </span>
                      Single sign-on
                    </h3>
                    <p className="pt-2 sm-font-main">
                      The{" "}
                      <a
                        href="https://blog.chromia.com/chromia-sso-the-whys-and-the-whats/"
                        target="_blank"
                        rel="noopener"
                        className="text-offwhite hover_pink"
                      >
                        Chromia Vault Single Sign On
                      </a>
                      (SSO) is a unique feature created to reduce password
                      entering without compromising users’ security.{" "}
                    </p>
                  </div>
                </div>
                {/* End Try Tool Col 2 Inner Col 5 */}
                {/* Start Try Tool Col 2 Inner Col 6 */}
                <div className="col-sm-6">
                  <div className="inner-tools-col inner-tools-col-6">
                    <h3>
                      <span>
                        <img
                          src="/images/try-tools/vault-icon.svg"
                          alt=""
                          width={35}
                          height={35}
                        />
                      </span>
                      Chromia Vault
                    </h3>
                    <p className="pt-2 sm-font-main">
                      <a
                        href="http://vault-testnet.chromia.com/"
                        target="_blank"
                        rel="noopener"
                        className="text-offwhite hover_pink"
                      >
                        Chromia Vault
                      </a>
                      allows users to manage their tokens and dapps on the
                      Chromia blockchain.
                    </p>
                  </div>
                </div>
                {/* End Try Tool Col 2 Inner Col 6 */}
              </div>
              {/* End Try Tool Col 2 Inner Row */}
            </div>
          </div>
          {/* End Try Tool Col 2 */}
        </div>
        {/* End Try Our Tools Row */}
      </div>
    </div>
    {/* End Try Our Tools Container */}
  </section>
  {/* ENd Try Our Tools Section */}
  {/* Start CHR Token Section */}
  <section className="chr-token-sec pt-6 md-pt">
    {/* Start CHR Token Container */}
    <div className="chr-token-container container pb-5 md-pb">
      {/* Start CHR Token Content */}
      <div className="chr-token-content">
        {/* Start CHR Token Row */}
        <div className="chr-token-row row justify-content-between">
          {/* Start CHR Token Col 1 */}
          <div className="col-md-5 md-mb">
            <div className="chr-token-col chr-token-col-1">
              <div className="chr-token-col-img">
                <picture className="lozad">
                  <source
                    type="image/webp"
                    srcSet="/static/img/chr_symbol-8163afb7-400.webp 400w, /static/img/chr_symbol-8163afb7-800.webp 800w, /static/img/chr_symbol-8163afb7-1200.webp 1200w"
                    sizes="400px"
                  />
                  <source
                    type="image/jpeg"
                    srcSet="/static/img/chr_symbol-8163afb7-400.jpeg 400w, /static/img/chr_symbol-8163afb7-800.jpeg 800w, /static/img/chr_symbol-8163afb7-1200.jpeg 1200w"
                    sizes="400px"
                  />
                  <img
                    src="/static/img/chr_symbol-8163afb7-400.jpeg"
                    alt="Chromia CHR Logo Icon"
                    loading="lazy"
                    decoding="async"
                    width={400}
                    height={397}
                  />
                </picture>
                {/* <img src="/images/chr-token/chr-token-logo.svg" alt="Chr Token Logo"  height="400" width ="400"> */}
              </div>
            </div>
          </div>
          {/* End CHR Token Col 1 */}
          {/* Start CHR Token Col 2 */}
          <div className="col-md-6 pr-md-5 pr-3">
            <div className="chr-token-col chr-token-col-2">
              <h2 className="font-heading sm-font-head text-offwhite mb-3">
                <span className="font-main">the</span>
                CHR token
              </h2>
              <p className="font-main sm-font-main mb-sm-4 mb-3">
                Chroma (CHR) is the native token designed to empower the Chromia
                platform and foster a mutually beneficial relationship between
                developers, users, and investors.
              </p>
              {/* Start Inner Section */}
              <section className="chr-token-inner">
                <h3 className="font-main-bold text-offwhite sm-font-main mb-4">
                  The CHR token’s main purposes
                </h3>
                {/* Start Inner Section Row */}
                <div className="chr-token-inner-row row justify-content-between">
                  {/* Start Inner Section Col 1 */}
                  <div className="col-sm-6 mb-4">
                    <div className="chr-inner-col chr-inner-col-1 d-flex justify-content-between align-items-center">
                      <div className="chr-img">
                        <img
                          src="/images/chr-token/platform-currency.svg"
                          alt=""
                          width={51}
                          height={45}
                        />
                      </div>
                      <div className="chr-txt">
                        <p className="sm-font-main font-main">
                          Act as platform currency
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End Inner Section Col 1 */}
                  {/* Start Inner Section Col 2 */}
                  <div className="col-sm-6 mb-4">
                    <div className="chr-inner-col chr-inner-col-2 d-flex justify-content-between align-items-center">
                      <div className="chr-img">
                        <img
                          src="/images/chr-token/hosting-fee.svg"
                          alt=""
                          width={51}
                          height={51}
                        />
                      </div>
                      <div className="chr-txt">
                        <p className="sm-font-main font-main">
                          Payment of hosting fees
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End Inner Section Col 2 */}
                  {/* Start Inner Section Col 3 */}
                  <div className="col-sm-6 mb-4">
                    <div className="chr-inner-col chr-inner-col-3 d-flex justify-content-between align-items-center">
                      <div className="chr-img">
                        <img
                          src="/images/chr-token/eco-staking.svg"
                          alt=""
                          width={51}
                          height={51}
                        />
                      </div>
                      <div className="chr-txt">
                        <p className="sm-font-main font-main">
                          Ecosystem staking
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End Inner Section Col 3 */}
                  {/* Start Inner Section Col 4 */}
                  <div className="col-sm-6 mb-5">
                    <div className="chr-inner-col chr-inner-col-4 d-flex justify-content-between align-items-center">
                      <div className="chr-img">
                        <img
                          src="/images/chr-token/system-purpose.svg"
                          alt=""
                          width={51}
                          height={51}
                        />
                      </div>
                      <div className="chr-txt">
                        <p className="sm-font-main font-main">
                          System-wide purposes
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End Inner Section Col 4 */}
                  {/* Start Inner Section Col 5 */}
                  <div className="col-sm-12">
                    <div className="chr-inner-col chr-inner-col-5">
                      <a
                        href="/chr-token/"
                        target="_blank"
                        rel="noopener"
                        className="btn btn-border text-center text-decoration-none text-offwhite font-main-bold sm-font-main bg-red"
                      >
                        Discover CHR
                      </a>
                    </div>
                  </div>
                  {/* End Inner Section Col 5 */}
                </div>
                {/* ENd Inner Section Row */}
              </section>
              {/* End Inner Section */}
            </div>
          </div>
          {/* End CHR Token Col 1 */}
        </div>
        {/* End CHR Token Row */}
      </div>
      {/* End CHR Token Content */}
    </div>
    {/* End CHR Token Container */}
  </section>
  {/* ENd CHR Token Section */}
  {/* Start Power to public section */}
  <section className="public-power-sec">
    {/* Start Power to public Container */}
    <div className="public-power-sec-container container pt-5 md-pt2">
      {/* Start Power to public Row */}
      <div className="public-power-row row justify-content-between pt-5 md-pt0">
        {/* Start Power to public Col 1 */}
        <div className="col-lg-4 md-mb2">
          <div className="public-power-col public-power-col-1">
            <h2 className="font-heading mb-4 sm-font-head">
              Power to the Public
            </h2>
            <p className="sm-font-main">
              We believe in a publicly hosted application infrastructure, a
              vision of a reformed internet which is truly aligned with the
              needs of their users. We believe in a public sector that is for
              the people, free of corruption and inefficiencies. We believe we
              have the tools needed, and we have been working on them since
              2012.
            </p>
            <h3 className="font-main-bold mt-3 mb-4 sm-font-head2">
              Pioneers of Blockchain
            </h3>
            <p className="sm-font-main">
              Chromia is the brainchild of ChromaWay, a pioneering blockchain
              company. Back in 2012, Alex Mizrahi the CTO started working
              together on what can be called the first implementation of
              user-defined assets on a blockchain, called colored coins. This
              creation was absolutely revolutionary. We are sure you have heard
              about tokens.
            </p>
            <h3 className="font-main-bold mt-3 mb-4 sm-font-head2">
              ChromaWay to Chromia
            </h3>
            <p className="sm-font-main">
              Since 2014, the three co-founders have been working on solutions
              for banks, enterprises, governments and private institutions and
              have built a team with people from all over the world. ChromaWay
              is a free and open source software company since always, and will
              not be controlling Chromia which is a true decentralized platform.
            </p>
          </div>
        </div>
        {/* End Power to public Col 1 */}
        {/* Start Power to public Col 2 */}
        <div className="col-lg-8 position-relative">
          <div className="public-power-col public-power-col-2 h-100">
            {/* Start Power to public Col 2 Inner ROw */}
            <div className="public-power-col-2-row row justify-content-around h-100">
              {/* Start Power to public Col 2 Inner Col 1 */}
              <div className="col-md-4 power-public-col-main d-flex justify-content-center align-items-center">
                <div className="public-power-inner-col public-power-inner-col-1 w-100">
                  <div className="public-power-inner-content position-relative">
                    <div
                      className="js-show-founder"
                      data-founder="founder-popup1"
                    >
                      {/* Start Founder ToolTip 1 */}
                      <div className="founder-tooltip founder-tooltip-1">
                        <p className="font-main-bold d-inline-block mr-2">
                          Did you know...
                        </p>
                        <button
                          className="btn btn-tooltip-1 d-inline-block"
                          type="button"
                          id="btn-tooltip1"
                        >
                          <i className="fas fa-chevron-down text-offwhite" />
                        </button>
                      </div>
                      {/* End Founder ToolTip 1 */}
                      {/* Start Founder Image 1 */}
                      <div className="founder-img">
                        <picture className="lozad">
                          <source
                            type="image/webp"
                            srcSet="/static/img/founder_1-190b1724-220.webp 220w"
                            sizes="220px"
                          />
                          <source
                            type="image/jpeg"
                            srcSet="/static/img/founder_1-190b1724-220.jpeg 220w"
                            sizes="220px"
                          />
                          <img
                            src="/static/img/founder_1-190b1724-220.jpeg"
                            alt="Or Perelman"
                            loading="lazy"
                            decoding="async"
                            width={220}
                            height={220}
                          />
                        </picture>
                      </div>
                      {/* End Founder Image 1 */}
                    </div>
                    {/* Start Founder Popup */}
                    <div
                      className="d-none founder-popup-container founder-popup-container-1"
                      id="founder-popup1"
                    >
                      {/* The Popup */}
                      <div className="popup">
                        <div className="popup-dialog">
                          <div className="popup-content bg-transparent">
                            {/* Popup body */}
                            <div className="popup-body p-0">
                              <div className="popup-head px-4 py-3 bg-red">
                                <h5 className="font-main-bold">
                                  Pioneers of Blockchain
                                </h5>
                              </div>
                              <div className="popup-content bg-darkred px-4 py-3">
                                <p className="p-0">
                                  Did you know that Or is featured in the very
                                  first article ever published about Bitcoin on
                                  TechCrunch? He was the co-founder of one of
                                  the very first Bitcoin wallets. The other
                                  co-founder was Stephan Thomas, who would later
                                  become the CTO of Ripple.
                                </p>
                              </div>
                            </div>
                            {/* End Popup body */}
                          </div>
                        </div>
                        {/* End Popup */}
                      </div>
                    </div>
                    {/* End Founder Popup */}
                    <div className="founder-txt founder-txt-1">
                      <h5 className="font-weight-normal">Or Perelman</h5>
                      <p>Co-founder &amp; COO</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Power to public Col 2 Inner Col 1 */}
              {/* Start Power to public Col 2 Inner Col 2 */}
              <div className="col-md-4 h-100 pt-5 md-pt2">
                {/* Start Power to public Col 2 Inner Col 2 Inner Row */}
                <div className="public-inner-main-col-2 d-flex flex-column justify-content-between h-100">
                  {/* Start Power to public Col 2 Inner Col 2 Inner Col 1 */}
                  <div className="public-power-inner-col public-power-inner-col-2 mb-md-3 md-mb2">
                    <div className="public-power-inner-content position-relative">
                      <div
                        className="js-show-founder"
                        data-founder="founder-popup2"
                      >
                        {/* Start Founder Tooltip 2  */}
                        <div className="founder-tooltip founder-tooltip-2">
                          <p className="font-main-bold d-inline-block mr-2">
                            Did you know...
                          </p>
                          <button
                            className="btn btn-tooltip-2 d-inline-block"
                            id="btn-tooltip2"
                          >
                            <i className="fas fa-chevron-down text-offwhite" />
                          </button>
                        </div>
                        {/* End Founder Tooltip 2  */}
                        {/* Start Founder Img 2  */}
                        <div className="founder-img">
                          <picture className="lozad">
                            <source
                              type="image/webp"
                              srcSet="/static/img/founder_2-1ffeae7-220.webp 220w"
                              sizes="220px"
                            />
                            <source
                              type="image/jpeg"
                              srcSet="/static/img/founder_2-1ffeae7-220.jpeg 220w"
                              sizes="220px"
                            />
                            <img
                              src="/static/img/founder_2-1ffeae7-220.jpeg"
                              alt="Henrik Hjelte"
                              loading="lazy"
                              decoding="async"
                              width={220}
                              height={220}
                            />
                          </picture>
                        </div>
                      </div>
                      {/* End Founder Img 2  */}
                      {/* Start Founder Popup */}
                      <div
                        className="d-none founder-popup-container founder-popup-container-2"
                        id="founder-popup2"
                      >
                        {/* Start Popup */}
                        <div className="popup">
                          <div className="popup-dialog">
                            <div className="popup-content bg-transparent">
                              {/* Popup body */}
                              <div className="popup-body p-0">
                                <div className="popup-head px-4 py-3 bg-pink">
                                  <h5 className="font-main-bold">
                                    Pioneers of Blockchain
                                  </h5>
                                </div>
                                <div className="popup-content bg-pinkdark px-4 py-3">
                                  <p className="p-0">
                                    Did you know that Henrik made an appearance
                                    on
                                    <em>Dragon’s Den?</em>
                                    He was pitching an idea for a startup
                                    company that aimed to create freedom on the
                                    internet. It was edited down to 16 seconds.
                                    Alex Mizrahi was also involved in this
                                    internet startup. Shortly after the
                                    <em>Dragon’s Den</em>
                                    appearance, Alex discovered Bitcoin. Henrik
                                    and Alex then came up with a new startup -
                                    that idea became ChromaWay and led to the
                                    creation of the Chromia platform.
                                  </p>
                                </div>
                              </div>
                              {/* End Popup body */}
                            </div>
                          </div>
                          {/* End Popup */}
                        </div>
                      </div>
                      {/* End Founder Popup */}
                      <div className="founder-txt founder-txt-2">
                        <h5 className="font-weight-normal">Henrik Hjelte</h5>
                        <p>Co-founder &amp; CEO</p>
                      </div>
                    </div>
                  </div>
                  {/* End Power to public Col 2 Inner Col 2 Inner Col 1 */}
                  {/* Start Power to public Col 2 Inner Col 2 Inner Col 2 */}
                  <div className="public-power-inner-col public-power-inner-col-3 mt-md-3">
                    <div className="public-power-inner-content position-relative">
                      <div
                        className="js-show-founder"
                        data-founder="founder-popup3"
                      >
                        {/* Start Founder Tooltip 3  */}
                        <div className="founder-tooltip founder-tooltip-3">
                          <p className="font-main-bold d-inline-block mr-2">
                            Did you know...
                          </p>
                          <button
                            className="btn btn-tooltip-3 d-inline-block"
                            id="btn-tooltip3"
                          >
                            <i className="fas fa-chevron-down text-offwhite" />
                          </button>
                        </div>
                        {/* Start Founder Tooltip 3  */}
                        {/* Start Founder Image 3  */}
                        <div className="founder-img">
                          <picture className="lozad">
                            <source
                              type="image/webp"
                              srcSet="/static/img/founder_3-837dada6-220.webp 220w"
                              sizes="220px"
                            />
                            <source
                              type="image/jpeg"
                              srcSet="/static/img/founder_3-837dada6-220.jpeg 220w"
                              sizes="220px"
                            />
                            <img
                              src="/static/img/founder_3-837dada6-220.jpeg"
                              alt="Alex Mizrahi"
                              loading="lazy"
                              decoding="async"
                              width={220}
                              height={220}
                            />
                          </picture>
                        </div>
                      </div>
                      {/* End Founder Image 3  */}
                      {/* Start Founder Popup */}
                      <div
                        className="d-none founder-popup-container founder-popup-container-3"
                        id="founder-popup3"
                      >
                        {/* The Popup */}
                        <div className="popup">
                          <div className="popup-dialog">
                            <div className="popup-content bg-transparent">
                              {/* Start Popup body */}
                              <div className="popup-body p-0">
                                <div className="popup-head px-4 py-3 bg-purple">
                                  <h5 className="font-main-bold">
                                    Pioneers of Blockchain
                                  </h5>
                                </div>
                                <div className="popup-content bg-purpledark px-4 py-3">
                                  <p className="p-0">
                                    Did you know that Alex was the founder and
                                    lead developer of the first blockchain token
                                    protocol,
                                    <em>Colored Coins?</em>
                                    Alex worked with a young whiz kid who helped
                                    write parts of the Colored Coins whitepaper.
                                    That kid was Vitalik Buterin, and the next
                                    whitepaper that kid wrote happened to be for
                                    something called Ethereum.
                                  </p>
                                </div>
                              </div>
                              {/* End Popup Body */}
                            </div>
                          </div>
                          {/* End Modal */}
                        </div>
                      </div>
                      {/* End Founder Popup */}
                      <div className="founder-txt founder-txt-3">
                        <h5 className="font-weight-normal">Alex Mizrahi</h5>
                        <p>Co-founder &amp; CTO</p>
                      </div>
                    </div>
                  </div>
                  {/* End Power to public Col 2 Inner Col 2 Inner Col 2 */}
                </div>
                {/* End Power to public Col 2 Inner Col 2 Inner Row */}
              </div>
              {/* End Power to public Col 2 Inner Col 2 */}
            </div>
            {/* End Power to public Col 2 Inner ROw */}
          </div>
        </div>
        {/* End Power to public Col 2 */}
      </div>
      {/* End Power to public Row */}
    </div>
    {/* End Power to public Container */}
  </section>
  {/* ENd Power to public section */}
  {/* Backers Section Start */}
  <section className="backers-sec mt-5 md-mt2">
    {/* Start Backers Section Container */}
    <div className="backers-container container">
      {/* Start Backers Content */}
      <div className="backers-content pt-5 md-pt0">
        <h2 className="font-heading mb-4 sm-font-head">Backers</h2>
        {/* Start Backers Row */}
        <div className="backers-row row flex-wrap justify-content-between">
          {/* Start Backers Col 1 */}
          <div className="col-md-3">
            <div className="backer-col backer-col-1 bg-dark-2 mb-3 d-flex justify-content-center align-items-center p-3">
              <picture>
                <img
                  src="/images/backers/21m-capital.svg"
                  alt="21M Capital"
                  width={160}
                  height={31}
                />
              </picture>
            </div>
          </div>
          {/* End Backers Col 1 */}
          {/* Start Backers Col 2 */}
          <div className="col-md-3">
            <div className="backer-col backer-col-2 bg-dark-2 mb-3 d-flex justify-content-center align-items-center p-3">
              <picture className="lozad">
                <source
                  type="image/webp"
                  srcSet="/static/img/arrington_xrp_capital-7fa667e9-160.webp 160w, /static/img/arrington_xrp_capital-7fa667e9-320.webp 320w, /static/img/arrington_xrp_capital-7fa667e9-480.webp 480w"
                  sizes="160px"
                />
                <source
                  type="image/jpeg"
                  srcSet="/static/img/arrington_xrp_capital-7fa667e9-160.jpeg 160w, /static/img/arrington_xrp_capital-7fa667e9-320.jpeg 320w, /static/img/arrington_xrp_capital-7fa667e9-480.jpeg 480w"
                  sizes="160px"
                />
                <img
                  src="/static/img/arrington_xrp_capital-7fa667e9-160.jpeg"
                  alt="arrington XRP capital"
                  loading="lazy"
                  decoding="async"
                  width={160}
                  height={62}
                />
              </picture>
            </div>
          </div>
          {/* End Backers Col 2 */}
          {/* Start Backers Col 3 */}
          <div className="col-md-3">
            <div className="backer-col backer-col-3 bg-dark-2 mb-3 d-flex justify-content-center align-items-center p-3">
              <picture className="lozad">
                <source
                  type="image/webp"
                  srcSet="/static/img/bitscale_capital-183a491f-160.webp 160w, /static/img/bitscale_capital-183a491f-320.webp 320w, /static/img/bitscale_capital-183a491f-480.webp 480w"
                  sizes="160px"
                />
                <source
                  type="image/jpeg"
                  srcSet="/static/img/bitscale_capital-183a491f-160.jpeg 160w, /static/img/bitscale_capital-183a491f-320.jpeg 320w, /static/img/bitscale_capital-183a491f-480.jpeg 480w"
                  sizes="160px"
                />
                <img
                  src="/static/img/bitscale_capital-183a491f-160.jpeg"
                  alt="bitscale capital"
                  loading="lazy"
                  decoding="async"
                  width={160}
                  height={60}
                />
              </picture>
            </div>
          </div>
          {/* ENd Backers Col 3 */}
          {/* Start Backers Col 4 */}
          <div className="col-md-3">
            <div className="backer-col backer-col-4 bg-dark-2 mb-3 d-flex justify-content-center align-items-center p-3">
              <picture className="lozad">
                <source
                  type="image/webp"
                  srcSet="/static/img/bytesize_capital-a96845d7-160.webp 160w, /static/img/bytesize_capital-a96845d7-320.webp 320w, /static/img/bytesize_capital-a96845d7-480.webp 480w"
                  sizes="160px"
                />
                <source
                  type="image/jpeg"
                  srcSet="/static/img/bytesize_capital-a96845d7-160.jpeg 160w, /static/img/bytesize_capital-a96845d7-320.jpeg 320w, /static/img/bytesize_capital-a96845d7-480.jpeg 480w"
                  sizes="160px"
                />
                <img
                  src="/static/img/bytesize_capital-a96845d7-160.jpeg"
                  alt="bytesize capital"
                  loading="lazy"
                  decoding="async"
                  width={160}
                  height={50}
                />
              </picture>
            </div>
          </div>
          {/* End Backers Col 4 */}
          {/* Start Backers Col 5 */}
          <div className="col-md-3">
            <div className="backer-col backer-col-5 bg-dark-2 mb-3 d-flex justify-content-center align-items-center p-3">
              <picture className="lozad">
                <source
                  type="image/webp"
                  srcSet="/static/img/factblock-bff0815c-160.webp 160w, /static/img/factblock-bff0815c-320.webp 320w, /static/img/factblock-bff0815c-480.webp 480w"
                  sizes="160px"
                />
                <source
                  type="image/jpeg"
                  srcSet="/static/img/factblock-bff0815c-160.jpeg 160w, /static/img/factblock-bff0815c-320.jpeg 320w, /static/img/factblock-bff0815c-480.jpeg 480w"
                  sizes="160px"
                />
                <img
                  src="/static/img/factblock-bff0815c-160.jpeg"
                  alt="factblock"
                  loading="lazy"
                  decoding="async"
                  width={160}
                  height={20}
                />
              </picture>
            </div>
          </div>
          {/* End Backers Col 5 */}
          {/* Start Backers Col 6 */}
          <div className="col-md-3">
            <div className="backer-col backer-col-6 bg-dark-2 mb-3 d-flex justify-content-center align-items-center p-3">
              <picture className="lozad">
                <source
                  type="image/webp"
                  srcSet="/static/img/gem_capital-464b0737-160.webp 160w, /static/img/gem_capital-464b0737-320.webp 320w, /static/img/gem_capital-464b0737-480.webp 480w"
                  sizes="160px"
                />
                <source
                  type="image/jpeg"
                  srcSet="/static/img/gem_capital-464b0737-160.jpeg 160w, /static/img/gem_capital-464b0737-320.jpeg 320w, /static/img/gem_capital-464b0737-480.jpeg 480w"
                  sizes="160px"
                />
                <img
                  src="/static/img/gem_capital-464b0737-160.jpeg"
                  alt="gem capital"
                  loading="lazy"
                  decoding="async"
                  width={160}
                  height={22}
                />
              </picture>
            </div>
          </div>
          {/* End Backers Col 6 */}
          {/* Start Backers Col 7 */}
          <div className="col-md-3">
            <div className="backer-col backer-col-7 bg-dark-2 mb-3 d-flex justify-content-center align-items-center p-3">
              <picture className="lozad">
                <source
                  type="image/webp"
                  srcSet="/static/img/jrr_crypto-fdd39df3-160.webp 160w, /static/img/jrr_crypto-fdd39df3-320.webp 320w, /static/img/jrr_crypto-fdd39df3-480.webp 480w"
                  sizes="160px"
                />
                <source
                  type="image/jpeg"
                  srcSet="/static/img/jrr_crypto-fdd39df3-160.jpeg 160w, /static/img/jrr_crypto-fdd39df3-320.jpeg 320w, /static/img/jrr_crypto-fdd39df3-480.jpeg 480w"
                  sizes="160px"
                />
                <img
                  src="/static/img/jrr_crypto-fdd39df3-160.jpeg"
                  alt="jrr crypto"
                  loading="lazy"
                  decoding="async"
                  width={160}
                  height={47}
                />
              </picture>
            </div>
          </div>
          {/* End Backers Col 7 */}
          {/* Start Backers Col 8 */}
          <div className="col-md-3">
            <div className="backer-col backer-col-8 bg-dark-2 mb-3 d-flex justify-content-center align-items-center p-3">
              <picture className="lozad">
                <source
                  type="image/webp"
                  srcSet="/static/img/ngc_capital-b9207fd2-160.webp 160w, /static/img/ngc_capital-b9207fd2-320.webp 320w, /static/img/ngc_capital-b9207fd2-480.webp 480w"
                  sizes="160px"
                />
                <source
                  type="image/jpeg"
                  srcSet="/static/img/ngc_capital-b9207fd2-160.jpeg 160w, /static/img/ngc_capital-b9207fd2-320.jpeg 320w, /static/img/ngc_capital-b9207fd2-480.jpeg 480w"
                  sizes="160px"
                />
                <img
                  src="/static/img/ngc_capital-b9207fd2-160.jpeg"
                  alt="NGC capital"
                  loading="lazy"
                  decoding="async"
                  width={160}
                  height={65}
                />
              </picture>
            </div>
          </div>
          {/* ENd Backers Col 8 */}
        </div>
        {/* End Backers Row */}
      </div>
      {/* End Backers Content */}
    </div>
    {/* End Backers Section Container */}
  </section>
  {/* End Backers Section */}
  {/* Start Community Section */}
  <section className="community-sec mt-5 pt-3 md-mt2 md-pt0">
    {/* Start Community Container */}
    <div className="community-sec-container container">
      <h2 className="font-heading mb-5 md-mt2 sm-font-head text-center">
        Voices from the Community
      </h2>
      {/* Start Community Row */}
      <div className="community-sec-row row flex-row-reverse justify-content-center align-items-center">
        {/* Start Community Col 1 */}
        <div className="col-md-5 md-mb2">
          <div className="community-col community-col-1 py-5 px-4 position-relative">
            <div className="community-txt">
              <p className="light font-italic mb-2 sm-font-main">
                I found out that there was a workshop for Rell (the programming
                language for writing dapps on Chromia) in Stockholm. So I went
                and was pleasantly surprised how easy it looked.
              </p>
              <p className="light font-italic mb-3 sm-font-main">
                I always thought that building something on the blockchain would
                be difficult. However, with Chromia and Rell, it really is not.
              </p>
            </div>
            <div className="community-client mt-4">
              {/* Start Community Col 2 inner Row */}
              <div className="community-col-row d-flex justify-content-between align-items-center">
                <div className="community-col-img">
                  <picture className="lozad">
                    <source
                      type="image/webp"
                      srcSet="/static/img/community_col_1-8b64565b-154.webp 154w"
                      sizes="154px"
                    />
                    <source
                      type="image/jpeg"
                      srcSet="/static/img/community_col_1-8b64565b-154.jpeg 154w"
                      sizes="154px"
                    />
                    <img
                      src="/static/img/community_col_1-8b64565b-154.jpeg"
                      alt="Viktor Plane"
                      loading="lazy"
                      decoding="async"
                      width={154}
                      height={153}
                    />
                  </picture>
                </div>
                <div className="client-name">
                  <h6 className="font-main-bold position-relative">
                    Viktor Plane
                  </h6>
                  <p className="light font-italic sm-font-main">
                    Community pioneer April 2020
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Community Col 1 */}
        {/* Start Community Col 2 */}
        <div className="col-md-5">
          <div className="community-col community-col-2 py-5 px-4 position-relative">
            <div className="community-txt">
              <p className="light font-italic mb-2 sm-font-main">
                I was a private sales investor in Chromia and during the due
                course I got very much involved within the ecosystem as I really
                liked the Vision in the team members and promise in technology.
              </p>
              <p className="light font-italic mb-3 sm-font-main">
                One of the rare teams I have seen in this revolutionary
                Blockchain industry whose prime focus is to keep developing the
                technology rather than being distracted by other factors.
                Chromia is going to be the flag-bearer of this Blockchain
                industry.
              </p>
            </div>
            <div className="community-client mt-4">
              {/* Start Community Col 2 inner Row */}
              <div className="community-col-row d-flex justify-content-between align-items-center">
                <div className="community-col-img">
                  <picture className="lozad">
                    <source
                      type="image/webp"
                      srcSet="/static/img/vaibhav_img-e6234514-87.webp 87w"
                      sizes="87px"
                    />
                    <source
                      type="image/jpeg"
                      srcSet="/static/img/vaibhav_img-e6234514-87.jpeg 87w"
                      sizes="87px"
                    />
                    <img
                      src="/static/img/vaibhav_img-e6234514-87.jpeg"
                      alt="Vaibhav Gupta"
                      loading="lazy"
                      decoding="async"
                      width={87}
                      height={88}
                    />
                  </picture>
                </div>
                <div className="client-name">
                  <h6 className="font-main-bold position-relative">
                    Vaibhav Gupta
                  </h6>
                  <p className="light font-italic sm-font-main">
                    Community pioneer April 2020
                  </p>
                </div>
              </div>
              {/* End Community Col 2 inner Row*/}
            </div>
          </div>
        </div>
        {/* End Community Col 2 */}
      </div>
      {/* End Community Row */}
      <div className="community-btn text-center mt-5">
        <a
          href="/developers/"
          target="_blank"
          rel="noopener"
          className="btn btn-community btn-border d-inline-block text-offwhite font-main-bold bg-purple text-decoration-none py-2"
        >
          Start developing on Chromia
        </a>
      </div>
    </div>
    {/* End Community Container */}
  </section>
  {/* End Community Section */}
  {/* Start Join Community Sec */}
  <section className="join-community-sec bg-yellow mt-5 md-mt2">
    {/* Start Join Community Container */}
    <div className="join-community-container container">
      <div className="join-community-content">
        {/* Start Join Community Row */}
        <div className="join-community-row row justify-content-between">
          {/* Start Join Community Col 1 */}
          <div className="col-lg-6">
            <div className="join-community-col join-community-col-1">
              <h2 className="font-heading sm-font-head">
                Join the Chromia community
              </h2>
            </div>
          </div>
          {/* End Join Community Col 1 */}
          {/* Start Join Community Col 2 */}
          <div className="col-lg-6">
            <div className="join-community-col join-community-col-2 text-center">
              <p className="text-cdark font-main-bold mb-3 sm-font-main">
                Getting started or want to create together? Join us!
              </p>
              <a
                href="#"
                target="_blank"
                rel="noopener"
                className="btn btn-friendly btn-border d-inline-block bg-orange text-offwhite font-main-bold text-decoration-none py-2"
              >
                Follow us on Twitter
              </a>
              <span className="text-cdark d-block d-sm-inline-block mx-2 my-2 my-sm-0 sm-font-main">
                or
              </span>
              <a
                href="#"
                target="_blank"
                rel="noopener"
                className="btn btn-friendly-2 btn-border d-inline-block text-cdark font-main-bold text-decoration-none py-2"
              >
                Join our Telegram
              </a>
            </div>
          </div>
          {/* End Join Community Col 2 */}
        </div>
        {/* End Join Community Row */}
      </div>
    </div>
    {/* End Join Community Container */}
  </section>
  {/* End Join Community Sec */}
  {/* Start Latest from Chromia Sec */}
  <section className="blog-sec mt-4 pt-3 pb-5 md-pb2">
    {/* Start Latest from Chromia Container */}
    <div className="blog-container container">
      {/* Start Latest from Chromia Heading */}
      <div className="blog-head d-flex justify-content-between">
        <h2 className="font-heading sm-font-head">Latest from Chromia</h2>
        <a
          href="#"
          target="_blank"
          rel="noopener"
          className="d-inline-block font-main-bold text-yellow"
        >
          To the Chromia Blog{" "}
          <span className="d-inline-block ml-2">
            <img
              src="/images/blog/blog-arrow.png"
              alt="Arrow"
              width={26}
              height={14}
            />
          </span>
        </a>
      </div>
      {/* End Latest from Chromia Heading */}
      <div className="blog-post-container mt-4 pt-2 md-mt2 md-pt0">
        {/* Start Latest from Chromia Blog Row */}
        <div className="blog-row row justify-content-between">
          {/* Start Latest from Chromia Blog Col 1 */}
          <div className="col-lg-6 mb-3">
            <a
              href="https://blog.chromia.com/developer-experience-team-monthly-update-1-january-2023/"
              className="text-offwhite text-decoration-none"
              target="_blank"
              rel="noopener"
            >
              <div className="blog-col blog-col-1 px-3 py-4 rounded-sm position-relative">
                <div className="blog-inner-col-row row justify-content-between">
                  <div className="col-md-5 d-none d-md-block">
                    <div className="blog-inner-col-img">
                      <picture className="lozad">
                        <source
                          type="image/webp"
                          srcSet="/static/img/CHROMIA_JAN_UPDATE-d6926724-206.webp 206w, /static/img/CHROMIA_JAN_UPDATE-d6926724-412.webp 412w, /static/img/CHROMIA_JAN_UPDATE-d6926724-618.webp 618w"
                          sizes="206px"
                        />
                        <source
                          type="image/jpeg"
                          srcSet="/static/img/CHROMIA_JAN_UPDATE-d6926724-206.jpeg 206w, /static/img/CHROMIA_JAN_UPDATE-d6926724-412.jpeg 412w, /static/img/CHROMIA_JAN_UPDATE-d6926724-618.jpeg 618w"
                          sizes="206px"
                        />
                        <img
                          className="latest-blog-img"
                          src="/static/img/CHROMIA_JAN_UPDATE-d6926724-206.jpeg"
                          alt="Blogpost cover image"
                          loading="lazy"
                          decoding="async"
                          width={206}
                          height={115}
                        />
                      </picture>
                    </div>
                  </div>
                  <div className="col-md-7 mt-3 mt-sm-0 h-100">
                    <div className="blog-inner-col-txt mh-100 overflow-hidden">
                      <h5 className="font-heading mb-2 sm-font-main2">
                        Developer Experience Team - Monthly Update #1 (January
                        2023){" "}
                      </h5>
                      <p className="d-none d-md-block sm-font-main js-blog-inner-col-paragraph">
                        Greetings Chromia Developers and Community, Welcome to
                        the first installment of our mid-month Developer
                        Experience Team Update! The purpose of these updates is
                        to keep Chromia developers up to speed on the
                        improvements being made to documentation, tooling, and
                        network design to support them in the expansion of the
                        Chromia ecosystem. Moving forward, this series will
                        become technical in nature and be primarily directed at
                        developers. However, for our first installment, we will
                        be providing a ‘bi
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          {/* End Latest from Chromia Blog Col 1 */}
          {/* Start Latest from Chromia Blog Col 2 */}
          <div className="col-lg-6 mb-3">
            <a
              href="https://blog.chromia.com/december-2022-recap-the-year-in-review-and-the-year-ahead/"
              className="text-offwhite text-decoration-none"
              target="_blank"
              rel="noopener"
            >
              <div className="blog-col blog-col-2 px-3 py-4 rounded-sm position-relative">
                <div className="blog-inner-col-row row justify-content-between">
                  <div className="col-md-5 d-none d-md-block">
                    <div className="blog-inner-col-img">
                      <picture className="lozad">
                        <source
                          type="image/webp"
                          srcSet="/static/img/CHROMIA_Decmeber_Recap_Article-e42f5dd2-206.webp 206w, /static/img/CHROMIA_Decmeber_Recap_Article-e42f5dd2-412.webp 412w, /static/img/CHROMIA_Decmeber_Recap_Article-e42f5dd2-618.webp 618w"
                          sizes="206px"
                        />
                        <source
                          type="image/jpeg"
                          srcSet="/static/img/CHROMIA_Decmeber_Recap_Article-e42f5dd2-206.jpeg 206w, /static/img/CHROMIA_Decmeber_Recap_Article-e42f5dd2-412.jpeg 412w, /static/img/CHROMIA_Decmeber_Recap_Article-e42f5dd2-618.jpeg 618w"
                          sizes="206px"
                        />
                        <img
                          className="latest-blog-img"
                          src="/static/img/CHROMIA_Decmeber_Recap_Article-e42f5dd2-206.jpeg"
                          alt="Blogpost cover image"
                          loading="lazy"
                          decoding="async"
                          width={206}
                          height={115}
                        />
                      </picture>
                    </div>
                  </div>
                  <div className="col-md-7 mt-3 mt-sm-0 h-100">
                    <div className="blog-inner-col-txt mh-100 overflow-hidden">
                      <h5 className="font-heading mb-2 sm-font-main2">
                        December 2022 Recap - The Year in Review, and the Year
                        Ahead{" "}
                      </h5>
                      <p className="d-none d-md-block sm-font-main js-blog-inner-col-paragraph">
                        Chromians, Surprise, the December update is being
                        released early this month! This allows the team and
                        community to wrap up 2022, then kick back and enjoy the
                        holidays. This month’s update will be taking a look back
                        at the year that was, while giving a glimpse into some
                        of the exciting changes coming in the New Year. Changes
                        are Coming to the Monthly Updates Over the past year,
                        our development team has completed preliminary coding on
                        several important mainnet components. A handful of other
                        c
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          {/* End Latest from Chromia Blog Col 2 */}
          {/* Start Latest from Chromia Blog Col 3 */}
          <div className="col-lg-6 mb-3">
            <a
              href="https://blog.chromia.com/chromia-fixes-this-centralized-nft-metadata-and-images/"
              className="text-offwhite text-decoration-none"
              target="_blank"
              rel="noopener"
            >
              <div className="blog-col blog-col-3 px-3 py-4 rounded-sm position-relative">
                <div className="blog-inner-col-row row justify-content-between">
                  <div className="col-md-5 d-none d-md-block">
                    <div className="blog-inner-col-img">
                      <picture className="lozad">
                        <source
                          type="image/webp"
                          srcSet="/static/img/2022_12_17_15-cfeacb75-206.webp 206w, /static/img/2022_12_17_15-cfeacb75-412.webp 412w, /static/img/2022_12_17_15-cfeacb75-618.webp 618w"
                          sizes="206px"
                        />
                        <source
                          type="image/jpeg"
                          srcSet="/static/img/2022_12_17_15-cfeacb75-206.jpeg 206w, /static/img/2022_12_17_15-cfeacb75-412.jpeg 412w, /static/img/2022_12_17_15-cfeacb75-618.jpeg 618w"
                          sizes="206px"
                        />
                        <img
                          className="latest-blog-img"
                          src="/static/img/2022_12_17_15-cfeacb75-206.jpeg"
                          alt="Blogpost cover image"
                          loading="lazy"
                          decoding="async"
                          width={206}
                          height={115}
                        />
                      </picture>
                    </div>
                  </div>
                  <div className="col-md-7 mt-3 mt-sm-0 h-100">
                    <div className="blog-inner-col-txt mh-100 overflow-hidden">
                      <h5 className="font-heading mb-2 sm-font-main2">
                        Chromia Fixes This: Centralized NFT Metadata and Images{" "}
                      </h5>
                      <p className="d-none d-md-block sm-font-main js-blog-inner-col-paragraph">
                        This Cointelegraph article
                        [https://cointelegraph.com/news/nfts-minted-on-ftx-break-highlighting-web2-hosting-flaws]
                        published last week (on December 8, 2022) caught our
                        attention. It highlights a significant flaw in the
                        current design of NFTs, a flaw which we have set out to
                        correct. Read on to learn more: What’s the story? Owners
                        of Solana based NFTs minted on the FTX platform have
                        found themselves holding ‘broken’ tokens which no longer
                        function properly. This is a result of the NFTs using
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          {/* End Latest from Chromia Blog Col 3 */}
          {/* Start Latest from Chromia Blog Col 4 */}
          <div className="col-lg-6 mb-3">
            <a
              href="https://blog.chromia.com/november-2022-recap-and-development-update/"
              className="text-offwhite text-decoration-none"
              target="_blank"
              rel="noopener"
            >
              <div className="blog-col blog-col-4 px-3 py-4 rounded-sm position-relative">
                <div className="blog-inner-col-row row justify-content-between">
                  <div className="col-md-5 d-none d-md-block">
                    <div className="blog-inner-col-img">
                      <picture className="lozad">
                        <source
                          type="image/webp"
                          srcSet="/static/img/CHROMIA_UPDATE-8d4c9b8c-206.webp 206w, /static/img/CHROMIA_UPDATE-8d4c9b8c-412.webp 412w, /static/img/CHROMIA_UPDATE-8d4c9b8c-618.webp 618w"
                          sizes="206px"
                        />
                        <source
                          type="image/jpeg"
                          srcSet="/static/img/CHROMIA_UPDATE-8d4c9b8c-206.jpeg 206w, /static/img/CHROMIA_UPDATE-8d4c9b8c-412.jpeg 412w, /static/img/CHROMIA_UPDATE-8d4c9b8c-618.jpeg 618w"
                          sizes="206px"
                        />
                        <img
                          className="latest-blog-img"
                          src="/static/img/CHROMIA_UPDATE-8d4c9b8c-206.jpeg"
                          alt="Blogpost cover image"
                          loading="lazy"
                          decoding="async"
                          width={206}
                          height={115}
                        />
                      </picture>
                    </div>
                  </div>
                  <div className="col-md-7 mt-3 mt-sm-0 h-100">
                    <div className="blog-inner-col-txt mh-100 overflow-hidden">
                      <h5 className="font-heading mb-2 sm-font-main2">
                        November 2022 Recap and Development Update{" "}
                      </h5>
                      <p className="d-none d-md-block sm-font-main js-blog-inner-col-paragraph">
                        Read Chromia November 2022 Recap and Development Updates
                        in our recent article.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          {/* End Latest from Chromia Blog Col 4 */}
        </div>
        {/* End Latest from Chromia Blog Row */}
      </div>
    </div>
    {/* End Latest from Chromia Container*/}
  </section>
  {/* End Latest from Chromia Sec */}
  <section className="newsletter-sec bg-purple py-5 md-py2">
    {/* Start Newsletter Container */}
    <div className="newsletter-container container">
      {/* Start Newsletter Content */}
      <div className="newsletter-content py-3 mx-auto">
        <h2 className="font-heading text-cdark text-center mb-4 sm-font-head">
          Subscribe to our newsletter
        </h2>
        <form
          action="https://chromaway.us13.list-manage.com/subscribe/post?u=40717d7a493ac7c48b9533d09&id=78394188c0"
          method="post"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          rel="noopener"
          noValidate=""
        >
          <label className="text-cdark mb-4 text-center sm-font-main d-block">
            Join 28.000 others interested in our monthly updates.
          </label>
          <div className="newsletter-input-container d-flex">
            <input
              type="email"
              name="EMAIL"
              className="js-email form-control btn-border border bg-transparent"
              placeholder="Enter your email"
              required="required"
            />
            {/* <button class="btn btn-newsletter btn-border bg-dark-2"><a href="#" class="d-inline-block text-offwhite">Subscribe</a></button> */}
            {/*    real people should not fill this in and expect good things - do not remove this or risk form bot signups   */}
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_40717d7a493ac7c48b9533d09_78394188c0"
                tabIndex={-1}
                defaultValue=""
              />
            </div>
            <input
              type="submit"
              defaultValue="Subscribe"
              name="subscribe"
              className="js-mc-submit btn btn-newsletter btn-border border-0 bg-dark-2 d-inline-block text-offwhite"
            />
          </div>
          <div className="js-email-msg-area newsletr-msg font-main-bold px-3 mt-1" />
        </form>
      </div>
      {/* End Newsletter Content */}
    </div>
    {/* End Newsletter Container */}
  </section>
  {/* Start Footer Section */}
  <footer>
    <section className="footersec mt-3 pt-5 md-pt2">
      {/* Start Footer Container */}
      <div className="footer-container container">
        {/* Start Footer Row */}
        <div className="footer-row row justify-content-between">
          {/* Start Footer Col 1 */}
          <div className="col-md-3 md-mb2">
            <div className="footer-col footer-col-1">
              <a href="/" className="text-offwhite text-decoration-none">
                <picture>
                  <img
                    src="/images/chromia-logo.svg"
                    alt="Chromia Logo"
                    width={190}
                    height={49}
                  />
                </picture>
              </a>
            </div>
          </div>
          {/* End Footer Col 1 */}
          {/* Start Footer Col 2 */}
          <div className="col-md-8">
            <div className="footer-col footer-col-2">
              {/* Start Footer Col 2 Inner Row */}
              <div className="footer-col-2-row row justify-content-between pb-3">
                {/* Start Footer Col 2 Inner Col 1 */}
                
                {/* End Footer Col 2 Inner Col 1 */}
                {/* Start Footer Col 2 Inner Col 2 */}
                <div className="col-sm-5 col-lg-3 mb-md-4 mb-lg-0 md-mb">
                  <div className="footer-inner-col footer-inner-col-2">
                    <p className="mb-3">Chromia</p>
                    <ul>
                      <li>
                        <a
                          href="#"
                          target="_blank"
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          Blog
                        </a>
                      </li>
                      <li>
                        <a
                          href="/mediakit/"
                          target="_blank"
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          Press &amp; Media kit
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          target="_blank"
                          rel="noopener"
                          className="coin text-offwhite text-decoration-none"
                        >
                          CoinMarketCap
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Footer Col 2 Inner Col 2 */}
                {/* Start Footer Col 2 Inner Col 3 */}
                <div className="col-sm-5 col-lg-3 md-mb">
                  <div className="footer-inner-col footer-inner-col-3">
                    <p className="mb-3">Developers</p>
                    <ul>
                      <li>
                        <a
                          href="/developers/"
                          className="text-offwhite text-decoration-none"
                        >
                          Developer Portal
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          target="_blank"
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          Web IDE
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          target="_blank"
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          Documentation
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Footer Col 2 Inner Col 3 */}
                {/* Start Footer Col 2 Inner Col 4 */}
                <div className="col-sm-5 col-lg-3">
                  <div className="footer-inner-col footer-inner-col-4">
                    <p className="mb-3">Whitepaper</p>
                    <ul>
                      <li>
                        <a
                          href="#"
                          target="_blank"
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          English version
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          target="_blank"
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          Chinese version
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          target="_blank"
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          Korean version
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          target="_blank"
                          rel="noopener"
                          className="text-offwhite text-decoration-none"
                        >
                          Spanish version
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Footer Col 2 Inner Col 4 */}
              </div>
              {/* End Footer Col 2 Inner Row */}
            </div>
          </div>
          {/* ENd Footer Col 2 */}
        </div>
        {/* End Footer Row */}
        {/* Start Footer Bar */}
        <div className="footer-bar py-4">
          <div className="footer-bar-row d-flex justify-content-between">
            <a
              href="mailto:info@chromia.com"
              className="text-yellow text-decoration-none"
            >
              info@chromia.com
            </a>
            <p>© 2023 Chromia</p>
          </div>
        </div>
        {/* End Footer Bar */}
      </div>
      {/* End Footer Container */}
    </section>
  </footer>
  {/* End Footer Section */}
</>




     

    

      
     
     
     </>
  );
};
export default Home;
